import { useEffect, useState } from 'react'
import { ScrollView, Text, View } from 'react-native'

import { faCheckSquare } from '@fortawesome/free-regular-svg-icons'
import {
  faBook,
  faCheck,
  faPen,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { NativeStackScreenProps } from '@react-navigation/native-stack'

import { getListItems } from '../API/Items'
import { deleteList } from '../API/List'
import { leaveList, updateAccess, usersInList } from '../API/ListAccess'
import { selfUser } from '../API/User'
import { LishaElementGroup } from '../Components/Element'
import Group from '../Components/Group'
import Navigation from '../Components/Navigation'
import OptionsFlyout from '../Components/OptionsFylout'
import SidebarNavigation from '../Components/SidebarNavigation'
import TopBarFlyout from '../Components/TopBarFlyout'
import FListCreator from '../Fragments/FListCreator'
import FListDelete from '../Fragments/FListDelete'
import FListMember from '../Fragments/FListMember'
import FListSettings from '../Fragments/FListSettings'
import useTheme from '../hooks/useTheme'
import IAPIItemsResponse from '../interfaces/IAPIItemsResponse'
import IAPIListDetails from '../interfaces/IAPIListDetails'
import IAPIUser from '../interfaces/IAPIUser'
import Lang from '../lang/Lang'
import { RootStackParamList } from '../RootStackParamList'
import { BodyStyle } from '../Style'
import { Theme } from '../Themes/Theme'

export default function ListSettings(
  nav: NativeStackScreenProps<RootStackParamList, 'ListSettings'>
) {
  const { listId } = nav.route.params
  const [list, setList] = useState<IAPIListDetails>()
  const [user, setUser] = useState<IAPIUser>()
  const [items, setItems] = useState<IAPIItemsResponse>()
  const [flyout, setFlyout] = useState(false)
  const [optionsUser, setOptions] = useState<IAPIUser | null>(null)

  function loadData() {
    usersInList(listId).then(setList)
    selfUser().then(setUser)
    getListItems(listId).then(setItems)
  }

  useEffect(() => {
    if (!listId) return
    loadData()
  }, [listId])

  function setUserPermissions(
    id: number,
    userId: number,
    read: boolean,
    write: boolean,
    update: boolean,
    del: boolean
  ) {
    updateAccess(id, userId, read, write, update, del).then(() => {
      if (!optionsUser) return
      loadData()
      setOptions({
        ...optionsUser,
        read,
        write,
        update,
        delete: del,
      })
    })
  }

  const isListAdmin = list?.admin.id === user?.id

  function fragmentOnChange() {
    nav.navigation.replace('ListSettings', { listId })
  }
  const theme = useTheme()

  return (
    <SidebarNavigation {...nav}>
      <View style={BodyStyle(theme)}>
        <Navigation
          title={Lang.get('listSettings')}
          subTitle={list?.name}
          key={list?.name}
          {...nav}
        />
        {flyout && (
          <TopBarFlyout
            items={[
              {
                onClick: () => {
                  if (!list) return
                  if (isListAdmin) {
                    deleteList(list.id)
                      .then(() => {
                        nav.navigation.pop()
                        nav.navigation.replace('Home')
                      })
                      .catch(() => {})
                  } else {
                    leaveList(list.id).then(() => {
                      nav.navigation.pop()
                      nav.navigation.replace('Home')
                    })
                  }
                },
                title: 'Yes, delete now Please!',
              },
            ]}
          />
        )}
        <ScrollView>
          {list ? (
            <View key={list.name}>
              <Group title={Lang.get('baseSettings')}>
                <FListSettings {...list} />
              </Group>

              <Group title={Lang.get('creator')}>
                <FListCreator {...list} />
              </Group>

              <Group title={Lang.get('listStatistics')}>
                <>
                  <LishaElementGroup
                    elements={[
                      {
                        text: Lang.get('numberOfItems'),
                        textRight: `${
                          (items?.items.unbought.length || 0) +
                          (items?.items.bought.length || 0)
                        }`,
                      },
                      {
                        text: Lang.get('numberOfItemsBought'),
                        textRight: `${items?.items.bought.length || 0}`,
                      },
                      {
                        text: Lang.get('numberOfItemsUnbought'),
                        textRight: `${items?.items.unbought.length || 0}`,
                      },
                    ]}
                  />
                  <LishaElementGroup
                    elements={[
                      {
                        text: Lang.get('amountOfItems'),
                        textRight: `${
                          (items?.items.unbought
                            .map(e => e.amount)
                            .reduce((prev, curr) => prev + curr, 0) || 0) +
                          (items?.items.bought
                            .map(e => e.amount)
                            .reduce((prev, curr) => prev + curr, 0) || 0)
                        }`,
                      },
                      {
                        text: Lang.get('amountOfItemsBought'),
                        textRight: `${
                          items?.items.bought
                            .map(e => e.amount)
                            .reduce((prev, curr) => prev + curr, 0) || 0
                        }`,
                      },
                      {
                        text: Lang.get('amountOfItemsUnbought'),
                        textRight: `${
                          items?.items.unbought
                            .map(e => e.amount)
                            .reduce((prev, curr) => prev + curr, 0) || 0
                        }`,
                      },
                    ]}
                  />
                </>
              </Group>

              <Group title={Lang.get('member')}>
                <FListMember
                  onOption={usr => {
                    setOptions(usr)
                  }}
                  {...list}
                  onChange={fragmentOnChange}
                />
              </Group>

              <FListDelete
                {...list}
                onChange={fragmentOnChange}
                isAdmin={isListAdmin}
                setFlyout={setFlyout}
              />
            </View>
          ) : (
            <Text style={{ color: Theme.get('DefaultColor', theme) }}>
              Loading...
            </Text>
          )}
        </ScrollView>
        {optionsUser && (
          <OptionsFlyout
            elements={[
              {
                text: Lang.get('permissionRead'),
                iconLeft: faBook,
                iconRight: optionsUser.read ? faCheck : faTimes,
                iconRightColor: Theme.get(
                  optionsUser.read
                    ? 'backgroundColorSuccess'
                    : 'backgroundColorDanger',
                  theme
                ),
                onPress: () =>
                  setUserPermissions(
                    listId,
                    optionsUser.id,
                    !optionsUser.read,
                    optionsUser.write,
                    optionsUser.update,
                    optionsUser.delete
                  ),
              },
              {
                text: Lang.get('permissionWrite'),
                iconLeft: faPen,
                iconRight: optionsUser.write ? faCheck : faTimes,
                iconRightColor: Theme.get(
                  optionsUser.write
                    ? 'backgroundColorSuccess'
                    : 'backgroundColorDanger',
                  theme
                ),
                onPress: () =>
                  setUserPermissions(
                    listId,
                    optionsUser.id,
                    optionsUser.read,
                    !optionsUser.write,
                    optionsUser.update,
                    optionsUser.delete
                  ),
              },
              {
                text: Lang.get('permissionUpdate'),
                iconLeft: faCheckSquare,
                iconRight: optionsUser.update ? faCheck : faTimes,
                iconRightColor: Theme.get(
                  optionsUser.update
                    ? 'backgroundColorSuccess'
                    : 'backgroundColorDanger',
                  theme
                ),
                onPress: () =>
                  setUserPermissions(
                    listId,
                    optionsUser.id,
                    optionsUser.read,
                    optionsUser.write,
                    !optionsUser.update,
                    optionsUser.delete
                  ),
              },
              {
                text: Lang.get('permissionDelete'),
                iconLeft: faTrash,
                iconRight: optionsUser.delete ? faCheck : faTimes,
                iconRightColor: Theme.get(
                  optionsUser.delete
                    ? 'backgroundColorSuccess'
                    : 'backgroundColorDanger',
                  theme
                ),
                onPress: () =>
                  setUserPermissions(
                    listId,
                    optionsUser.id,
                    optionsUser.read,
                    optionsUser.write,
                    optionsUser.update,
                    !optionsUser.delete
                  ),
              },
            ]}
            onGone={() => {
              setOptions(null)
            }}
            title="permissions"
          />
        )}
      </View>
    </SidebarNavigation>
  )
}
