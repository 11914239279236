import { useEffect, useState } from 'react'

import { faUsers } from '@fortawesome/free-solid-svg-icons'

import { getAdminUsers, IAPIAdminUsers } from '../API/admin/AdminUsers'
import { LishaElementGroup } from '../Components/Element'
import { NavigationProps } from '../Components/Navigation'
import Lang from '../lang/Lang'

export default function AdminHomeUsers(nav: NavigationProps<'Home'>) {
  const [users, setUsers] = useState<IAPIAdminUsers[]>([])

  useEffect(() => {
    getAdminUsers().then(setUsers)
  }, [])

  return (
    <LishaElementGroup
      title={Lang.get('administration')}
      elements={[
        {
          text: Lang.get('users'),
          iconLeft: faUsers,
          textRight: `${users.length}`,
          onPress: () => {
            nav.navigation.push('Users')
          },
        },
      ]}
    />
  )
}
