import { useEffect, useState } from 'react'
import { Pressable, Text } from 'react-native'

import useTheme from '../hooks/useTheme'
import { Theme } from '../Themes/Theme'

type TAlertType = 'info' | 'danger' | 'warning' | 'success'

interface IAlert {
  text: string
  onPress?: () => void
}

interface IAlertFull extends IAlert {
  type: TAlertType
}

export default function Alert(props: IAlertFull) {
  const theme = useTheme()
  const [color, setColor] = useState<string>('')
  const [backgroundColor, setBackgroundColor] = useState<string>('')

  useEffect(() => {
    setColor(
      {
        info: Theme.get('textColorInfo', theme),
        danger: Theme.get('textColorDanger', theme),
        warning: Theme.get('textColorWarning', theme),
        success: Theme.get('textColorSuccess', theme),
      }[props.type]
    )

    setBackgroundColor(
      {
        info: Theme.get('backgroundColorInfo', theme),
        danger: Theme.get('backgroundColorDanger', theme),
        warning: Theme.get('backgroundColorWarning', theme),
        success: Theme.get('backgroundColorSuccess', theme),
      }[props.type]
    )
  }, [theme])

  return (
    <>
      <Pressable onPress={props.onPress}>
        <Text
          key={theme}
          style={{
            color,
            textAlign: 'center',
            backgroundColor,
            padding: 15,
            marginBottom: 10,
            borderRadius: 5,
          }}
        >
          {props.text}
        </Text>
      </Pressable>
    </>
  )
}

export function Info(props: IAlert) {
  return <Alert {...props} type="info" />
}

export function Success(props: IAlert) {
  return <Alert {...props} type="success" />
}

export function Danger(props: IAlert) {
  return <Alert {...props} type="danger" />
}

export function Warning(props: IAlert) {
  return <Alert {...props} type="warning" />
}
