import { ReactElement } from 'react'
import { useWindowDimensions, View } from 'react-native'

import { useHeaderHeight } from '@react-navigation/elements'

import useTheme from '../hooks/useTheme'
import Home from '../pages/Home'
import { desktopBreakPoint } from '../Style'
import { Theme } from '../Themes/Theme'
import { NavigationProps } from './Navigation'

export default function SidebarNavigation(
  nav: NavigationProps<any> & { children: ReactElement | ReactElement[] }
) {
  const { height, width } = useWindowDimensions()
  const theme = useTheme()
  const headerHeight = useHeaderHeight()

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: height - headerHeight,
      }}
    >
      {width >= desktopBreakPoint && (
        <View
          style={{
            width: 350,
            borderRightColor: Theme.get('borderColorLightGrey', theme),
            borderRightWidth: 1,
          }}
        >
          <Home {...(nav as NavigationProps<'Home'>)} />
        </View>
      )}
      <View style={{ flex: 1 }}>{nav.children}</View>
    </View>
  )
}
