import { ReactElement } from 'react'
import { Text, View } from 'react-native'

import useTheme from '../hooks/useTheme'
import { GroupStyle } from '../Style'

interface IGroup {
  title?: string
  children: ReactElement
}

export default function Group(props: IGroup) {
  const theme = useTheme()
  const style = GroupStyle(theme)

  return (
    <View style={style.box}>
      {props.title && (
        <View style={style.textBox}>
          <Text style={style.text}>{props.title}</Text>
        </View>
      )}
      {props.children}
    </View>
  )
}
