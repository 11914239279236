import { Pressable, Text, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import {
  faHome,
  faUser,
  faUtensils,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import useTheme from '../hooks/useTheme'
import { RootStackParamList } from '../RootStackParamList'
import { BottomNavigationStyle } from '../Style'
import { NavigationProps } from './Navigation'

export default function BottomNavigation(nav: NavigationProps<any>) {
  const theme = useTheme()
  const insets = useSafeAreaInsets()
  const style = BottomNavigationStyle(theme, insets)

  const menu: {
    name: string
    icon: IconDefinition
    page: keyof RootStackParamList
  }[] = [
    { name: 'Home', icon: faHome, page: 'Home' },
    // { name: 'Rezepte', icon: faUtensils, page: 'Recipes' },
    // { name: 'Foodplan', icon: faList, page: 'List' },
    { name: 'Profil', icon: faUser, page: 'User' },
  ]

  return (
    <View style={style.outerBox}>
      <View style={style.box}>
        {menu.map(item => (
          <Pressable
            key={item.name}
            style={[
              style.button,
              nav.route.name === item.page
                ? style.buttonActive
                : style.buttonInActive,
            ]}
            onPress={() => nav.navigation.replace(item.page)}
          >
            <FontAwesomeIcon
              size={18}
              color={style.icon.color}
              icon={item.icon}
              style={[
                style.icon,
                nav.route.name === item.page ? style.iconActive : {},
              ]}
            />
            {nav.route.name === item.page && (
              <Text style={[style.text]}>{item.name}</Text>
            )}
          </Pressable>
        ))}
      </View>
    </View>
  )
}
