import API from '../API'

export interface IAPIAdminUsers {
  email: string
  id: number
  name: string
  role: number
  role_text: string
  register_date: number
  lists: number
  token: number
  ownLists: number
  last_login: number
}

export const getAdminUsers = async () =>
  (await API()).get<IAPIAdminUsers[]>(`/user/all`).then(e => e.data)
