import IAPIMsg from '../interfaces/IAPIMsg'
import IAPIToken from '../interfaces/IAPIToken'
import IAPIUser from '../interfaces/IAPIUser'
import API from './API'

export const createUser = async (
  email: string,
  password: string,
  name: string
) =>
  (await API())
    .post<IAPIToken>('/user', { email, password, name })
    .then(e => e.data)

export const loginUser = async (email: string, password: string) =>
  (await API()).put<IAPIToken>('/user', { email, password }).then(e => e.data)

export const logoutUser = async () =>
  (await API()).delete<IAPIMsg>('/user').then(e => e.data)

export const selfUser = async () =>
  (await API()).get<IAPIUser>('/user').then(e => e.data)

export const relatedUser = async () =>
  (await API())
    .get<{ related: { email: string; name: string }[] }>('/user/related')
    .then(e => e.data)

export const updateUser = async (email: string, name: string) =>
  (await API())
    .put<{ success: boolean }>('/user/update', { email, name })
    .then(e => e.data)
