import { ScrollView, Text, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import Group from '../Components/Group'
import Hr from '../Components/Hr'
import Navigation, { NavigationProps } from '../Components/Navigation'
import SidebarNavigation from '../Components/SidebarNavigation'
import useTheme from '../hooks/useTheme'
import Lang from '../lang/Lang'
import { BodyStyle } from '../Style'

export default function PWA(nav: NavigationProps<'PWA'>) {
  const insets = useSafeAreaInsets()

  const Header = ({ text }: { text: string }) => (
    <Text
      style={{
        color: '#ffffff',
        marginBottom: 10,
        fontSize: 20,
        fontWeight: 'bold',
      }}
    >
      {text}
    </Text>
  )

  const Content = ({ text }: { text: string }) => (
    <Text
      style={{
        color: '#ffffff80',
        marginBottom: 10,
      }}
    >
      {text}
    </Text>
  )
  const theme = useTheme()

  return (
    <SidebarNavigation {...nav}>
      <View style={BodyStyle(theme)}>
        <Navigation back="Home" {...nav} title={Lang.get('pwa')} />
        <ScrollView style={{ marginBottom: insets.bottom }}>
          <Content text={Lang.get('pwa_headline')} />
          <Group title="iOS">
            <>
              <Header text={Lang.get('pwa_ios_chrome_title')} />
              <Content text={Lang.get('pwa_ios_chrome_text')} />
              <Hr />
              <Header text={Lang.get('pwa_ios_safari_title')} />
              <Content text={Lang.get('pwa_ios_safari_text')} />
            </>
          </Group>
          <Group title="Android">
            <>
              <Header text={'Wanted'} />
              <Content
                text={
                  "As we don't have android test devices, we need your help to gather a list of supported devices and browsers. Contact us via instagram/twitter @ReDiGermany"
                }
              />
            </>
          </Group>
        </ScrollView>
      </View>
    </SidebarNavigation>
  )
}
