import AsyncStorage from '@react-native-async-storage/async-storage'

import DE from './de'
import EN from './en'
import ILang from './ILang'

interface ILng {
  de: ILang
  en: ILang
}

type TLangs = keyof ILng
type TLangInputs = keyof ILang

class Lang {
  private lang: TLangs = 'de'

  private langs: ILng = {
    de: DE,
    en: EN,
  }

  constructor() {
    AsyncStorage.getItem('lang').then(lng => {
      if (!lng) return
      const lang: TLangs = lng ? (lng as TLangs) : 'de'

      this.setLang(lang)
    })
  }

  public setLang(lang: TLangs) {
    this.lang = lang
    AsyncStorage.setItem('lang', lang)
  }

  public get(name: TLangInputs, variables?: { [key: string]: string }) {
    let ret: string
    if (name in this.langs[this.lang]) {
      ret = this.langs[this.lang][name]
    } else if (name in this.langs.de) {
      ret = this.langs.de[name]
    } else {
      ret = 'NO_LANG_FOUND'
    }

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const k in variables) {
      ret = ret.replace(`{${k}}`, variables[k])
    }

    return ret
  }
}

export default new Lang()
