import { useEffect, useState } from 'react'
import { Text } from 'react-native'

import { faClock } from '@fortawesome/free-regular-svg-icons'
import { faPersonChalkboard, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Picker } from '@react-native-picker/picker'

import { inviteToList, removeFromList, usersInList } from '../API/ListAccess'
import { relatedUser } from '../API/User'
import { LishaElementGroup } from '../Components/Element'
import Form from '../Components/Form'
import useTheme from '../hooks/useTheme'
import IAPIListDetails from '../interfaces/IAPIListDetails'
import IAPIUser from '../interfaces/IAPIUser'
import IListFragment from '../interfaces/IListFragment'
import Lang from '../lang/Lang'
import {
  ButtonRemoveStyle,
  ButtonWarningStyle,
  SubHeadingStyle,
} from '../Style'
import { Theme } from '../Themes/Theme'

interface IFlistMember extends IListFragment {
  onOption: (_user: IAPIUser) => void
}

export default function FListMember(lst: IFlistMember) {
  const [list, setList] = useState<IAPIListDetails>(lst)
  const [related, setRelated] = useState<{
    related: { email: string; name: string }[]
  }>({
    related: [],
  })
  const [newMail, setNewMail] = useState('')

  useEffect(() => {
    relatedUser().then(e =>
      setRelated({
        related: [
          { email: Lang.get('addFriend'), name: Lang.get('addFriend') },
          ...e.related,
        ],
      })
    )
  }, [])

  const theme = useTheme()

  return (
    <>
      <LishaElementGroup
        key={list.user.length}
        elements={list.user
          .filter(e => e.id !== list.admin.id)
          .map(e => ({
            text: e.name,
            id: e.id,
            iconRight: e.accepted ? undefined : faClock,
            swipeRight: [
              {
                icon: faPersonChalkboard,
                style: ButtonWarningStyle(theme),
                onPress: () => {
                  const usr = list.user.filter(a => a.id === e.id)
                  if (usr.length <= 0) return

                  lst.onOption(usr[0])
                },
              },
              {
                icon: faTrash,
                style: ButtonRemoveStyle(theme),
                onPress: () => {
                  removeFromList(list.id, e.id).then(() => {
                    usersInList(list.id).then(setList)
                  })
                },
              },
            ],
          }))}
      />

      <Text style={SubHeadingStyle(theme)}>{Lang.get('newMember')}</Text>

      {related.related.length > 0 && (
        <Picker
          style={{
            backgroundColor: Theme.get('backgroundColorDarkGrey', theme),
            color: Theme.get('DefaultColor', theme),
            borderColor: 'transparent',
            paddingVertical: 5,
            // paddingHorizontal: 10,
            marginBottom: 15,
          }}
          selectedValue={newMail}
          onValueChange={e => {
            if (e === related.related[0].email) setNewMail('')
            else setNewMail(e)
          }}
        >
          {related.related.map(mail => (
            <Picker.Item
              key={mail.email}
              color={Theme.get('DefaultColor', theme)}
              label={mail.name === '' ? mail.email : mail.name}
              value={mail.email}
            />
          ))}
        </Picker>
      )}
      <Form<{ email: string }>
        key={newMail}
        fields={[{ name: 'email', value: newMail }]}
        onSubmit={async ({ email }) => {
          const ret = await inviteToList(list.id, email).catch(() => null)
          if (!ret) return [false, Lang.get('inviteError')]
          lst.onChange()

          return [true, Lang.get('invited')]
        }}
        submitText={Lang.get('invite')}
      />
    </>
  )
}
