import { View } from 'react-native'

import { NativeStackScreenProps } from '@react-navigation/native-stack'

import { createList } from '../API/List'
import Form from '../Components/Form'
import Navigation from '../Components/Navigation'
import SidebarNavigation from '../Components/SidebarNavigation'
import useTheme from '../hooks/useTheme'
import Lang from '../lang/Lang'
import { RootStackParamList } from '../RootStackParamList'
import { BodyStyle } from '../Style'

export default function NewList(
  nav: NativeStackScreenProps<RootStackParamList, 'NewList'>
) {
  const theme = useTheme()

  return (
    <SidebarNavigation {...nav}>
      <View style={BodyStyle(theme)}>
        <Navigation {...nav} title={Lang.get('newList')} back="Home" />
        <View>
          <Form<{ listname: string }>
            onSubmit={async data => {
              const text = data.listname
              if (text !== '') {
                const list = await createList(text).catch(() => null)
                if (list) {
                  nav.navigation.pop()
                  nav.navigation.push('List', { listId: list.id })

                  return [false, '']
                }

                return [true, Lang.get('loginError')]
              }

              return [true, Lang.get('loginError')]
            }}
            fields={[{ name: 'listname' }]}
            submitText={Lang.get('create')}
          />
        </View>
      </View>
    </SidebarNavigation>
  )
}
