import IAPIItemsResponse from '../interfaces/IAPIItemsResponse'
import API from './API'

export const getListItems = async (id: number) =>
  (await API()).get<IAPIItemsResponse>(`/list/${id}/items`).then(e => e.data)

export const addListItem = async (id: number, text: string, amount: number) =>
  (await API())
    .post<IAPIItemsResponse>(`/list/${id}/items`, { text, amount })
    .then(e => e.data)

export const updateListItem = async (
  id: number,
  text: string,
  amount: number,
  bought: boolean
) =>
  (await API())
    .put<IAPIItemsResponse>(`/list/${id}/items`, { text, amount, bought })
    .then(e => e.data)

export const deleteListItem = async (id: number, itemId: number) =>
  (await API())
    .delete<IAPIItemsResponse>(`/list/${id}/items/${itemId}`)
    .then(e => e.data)

export const deleteListItems = async (id: number) =>
  (await API()).delete<IAPIItemsResponse>(`/list/${id}/items`).then(e => e.data)

export const deleteBoughtListItems = async (id: number) =>
  (await API())
    .delete<IAPIItemsResponse>(`/list/${id}/items/bought`)
    .then(e => e.data)
