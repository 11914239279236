import Dark from './Dark'
import IColor from './IColor'
import Light from './Light'
import TTheme from './TTheme'

export const BottomBarInputHeight = 40

export class Theme {
  public static themes: { [_key in TTheme]: IColor } = {
    dark: Dark,
    light: Light,
  }

  public static get(color: keyof IColor, theme: TTheme) {
    return Theme.themes[theme][color]
  }
}
