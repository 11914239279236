import IColor from './IColor'

const Dark: IColor = {
  backgroundColorDarkGrey: '#111111',
  backgroundColorLightGrey: '#202020',
  borderColorLightGrey: '#202020',
  backgroundColorTransparent: 'transparent',
  backgroundColorToxicGreen: '#4ae53a',

  backgroundColorSuccess: '#249131',
  textColorSuccess: '#FFFFFF',
  strongBackgroundColorSuccess: '#249131',
  strongTextColorSuccess: '#FFFFFF',

  backgroundColorInfo: '#248291',
  textColorInfo: '#FFFFFF',
  strongBackgroundColorInfo: '#248291',
  strongTextColorInfo: '#FFFFFF',

  backgroundColorDanger: '#912424',
  textColorDanger: '#FFFFFF',
  strongBackgroundColorDanger: '#912424',
  strongTextColorDanger: '#FFFFFF',

  backgroundColorWarning: '#915924',
  textColorWarning: '#FFFFFF',
  strongBackgroundColorWarning: '#915924',
  strongTextColorWarning: '#FFFFFF',

  backgroundColorDarkGreyOpaque: '#111111EE',
  DefaultColor: '#FFFFFF',
  DefaultColorDimmed: '#FFFFFF80',

  TextColorLight: '#a7a7a7',
  BorderColorDark: '#555555',
  TextColorDimmed: '#999999',
  ColorFull: '#000000',
  ColorFull50: '#00000080',
}

export default Dark
