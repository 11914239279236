import IAPIListDetails from '../interfaces/IAPIListDetails'
import IAPIMsg from '../interfaces/IAPIMsg'
import API from './API'

export const inviteToList = async (id: number, email: string) =>
  (await API()).post<IAPIMsg>(`/list/${id}/user`, { email }).then(e => e.data)

export const removeFromList = async (id: number, userId: number) =>
  (await API()).delete<IAPIMsg>(`/list/${id}/user/${userId}`).then(e => e.data)

export const leaveList = async (id: number) =>
  (await API()).delete<IAPIMsg>(`/list/${id}/leave`).then(e => e.data)

export const usersInList = async (id: number) =>
  (await API()).get<IAPIListDetails>(`/list/${id}/user`).then(e => e.data)

export const updateInvite = async (id: number, accept: boolean) =>
  (await API()).put<IAPIMsg>(`/list/${id}/user`, { accept }).then(e => e.data)

export const updateAccess = async (
  id: number,
  userId: number,
  read: boolean,
  write: boolean,
  update: boolean,
  del: boolean
) =>
  (await API())
    .put<IAPIMsg>(`/list/${id}/user/${userId}`, {
      read,
      write,
      update,
      delete: del,
    })
    .then(e => e.data)
