import { useCallback, useEffect, useState } from 'react'
import {
  Pressable,
  RefreshControl,
  ScrollView,
  Text,
  useWindowDimensions,
  View,
} from 'react-native'

// import { useSafeAreaInsets } from 'react-native-safe-area-context'
import {
  faCheck,
  faPlus,
  faRefresh,
  faTrash,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useHeaderHeight } from '@react-navigation/elements'

import { getLists } from '../API/List'
import { updateInvite } from '../API/ListAccess'
import { selfUser } from '../API/User'
import BottomNavigation from '../Components/BottomNavigation'
import CheckPWA from '../Components/CheckPWA'
import { LishaElementGroup } from '../Components/Element'
import Navigation, { NavigationProps } from '../Components/Navigation'
import AdminHomeUsers from '../Fragments/AdminHomeUsers'
import useTheme from '../hooks/useTheme'
import IAPIListResponse from '../interfaces/IAPIListResponse'
import IAPIUser from '../interfaces/IAPIUser'
import Lang from '../lang/Lang'
import {
  BodyStyle,
  ButtonBoughtStyle,
  ButtonRemoveStyle,
  mainPadding,
} from '../Style'
import { Theme } from '../Themes/Theme'

export default function Home(nav: NavigationProps<'Home'>) {
  // const insets = useSafeAreaInsets()
  // const APP_VERSION = -1
  // @ts-ignore
  const { version: APP_VERSION } = process.env.APP_MANIFEST

  const [lists, setLists] = useState<IAPIListResponse>({
    own: [],
    shared: [],
    invited: [],
  })
  const [user, setUser] = useState<IAPIUser>()

  const [refreshing, setRefreshing] = useState(false)

  function loadData() {
    AsyncStorage.getItem('api_key')
      .catch(() => nav.navigation.replace('Login'))
      .then(() => {
        selfUser()
          .then(setUser)
          .catch(() => nav.navigation.replace('Login'))
        getLists().then(setLists)

        setRefreshing(false)
      })
  }

  useEffect(() => {
    loadData()

    const interval = setInterval(() => getLists().then(setLists), 60 * 1000)

    return () => clearInterval(interval)
  }, [])

  const onRefresh = useCallback(() => {
    setRefreshing(true)
    loadData()
  }, [])

  const theme = useTheme()

  const getCount = (count: number) => {
    if (count === 0) return Lang.get('itemCount0')

    if (count === 1) return Lang.get('itemCount')

    return Lang.get('itemCounts', { count: count.toString() })
  }

  const { height } = useWindowDimensions()
  const headerHeight = useHeaderHeight()

  return (
    <View style={{ ...BodyStyle(theme), paddingRight: 0 }}>
      <Navigation
        back="Home"
        {...nav}
        title={'LiSha'}
        key={user?.name}
        subTitle={Lang.get('welcome', {
          name: user?.name || Lang.get('human'),
        })}
        links={[
          {
            icon: faRefresh,
            onClick: loadData,
          },
          {
            icon: faPlus,
            onClick: () => {
              nav.navigation.push('NewList')
            },
          },
        ]}
      />
      <View
        style={{
          // marginBottom: insets.bottom,
          marginTop: -mainPadding,
          marginRight: -mainPadding,
          overflow: 'hidden',
          flex: 1,
          // marginBottom: 50,
        }}
      >
        <ScrollView
          refreshControl={
            <RefreshControl
              title={Lang.get('reloading')}
              tintColor="#fff"
              titleColor="#fff"
              colors={['#fff']}
              refreshing={refreshing}
              onRefresh={onRefresh}
            />
          }
          style={{
            // paddingBottom: insets.bottom,
            paddingRight: mainPadding,
            height: height - headerHeight,
            paddingBottom: 50,
          }}
        >
          <CheckPWA {...nav} />

          <LishaElementGroup
            elements={lists.own.map(item => ({
              text: item.name,
              id: item.id,
              onPress: () => {
                nav.navigation.push('List', { listId: item.id })
              },
              textRight: getCount(item.items.unbought),
              iconRight: item.user > 1 ? faUsers : undefined,
            }))}
          />

          {lists.shared.length > 0 && (
            <LishaElementGroup
              title={
                lists.own.length > 0 && lists.shared.length > 0
                  ? Lang.get('sharedLists')
                  : undefined
              }
              elements={lists.shared.map(item => ({
                text: item.name,
                id: item.id,
                onPress: () => {
                  nav.navigation.push('List', { listId: item.id })
                },
                textRight: getCount(item.items.unbought),
                bottomLeft: Lang.get('sharedBy', { name: item.admin }),
              }))}
            />
          )}

          {lists.invited.length > 0 && (
            <LishaElementGroup
              title={Lang.get('invitedLists')}
              elements={lists.invited.map(item => ({
                text: item.name,
                id: item.id,
                bottomLeft: Lang.get('invitedBy', { name: item.admin }),
                buttonRight: [
                  {
                    icon: faCheck,
                    style: ButtonBoughtStyle(theme),
                    onPress: () => {
                      updateInvite(item.id, true).then(loadData)
                    },
                  },
                  {
                    icon: faTrash,
                    style: ButtonRemoveStyle(theme),
                    onPress: () => {
                      updateInvite(item.id, false).then(loadData)
                    },
                  },
                ],
              }))}
            />
          )}

          {(user?.role || 0) > 0 && <AdminHomeUsers {...nav} />}

          <Pressable
            onPress={() => nav.navigation.push('Changelog')}
            // style={{ paddingBottom: insets.bottom || 15 }}
          >
            <Text
              style={{
                textAlign: 'center',
                color: Theme.get('DefaultColor', theme),
                opacity: 0.3,
              }}
            >
              v{APP_VERSION} - {Lang.get('changelog')}
            </Text>
          </Pressable>
        </ScrollView>
      </View>
      <BottomNavigation {...nav} />
    </View>
  )
}
