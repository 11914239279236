import ILang from './ILang'

const DE: ILang = {
  welcome: 'Willkommen zurück, {name}!',
  reloading: 'neu laden',
  login: 'Anmelden',
  email: 'E-Mail',
  password: 'Passwort',
  loggingIn: 'Sie werden eingeloggt.',
  loginError: 'Etwas ist schief gelaufen. Bitte versuche es erneut!',
  loginErrorUser:
    'Etwas ist schief gelaufen. Bitte versuche es erneut! (User Not found)',
  register: 'Konto erstellen',
  sharedLists: 'Geteilte Listen',
  create: 'Erstellen',
  listname: 'Listenname',
  newList: 'neue Liste',
  leaveList: 'Liste verlassen.',
  deleteList: 'Liste löschen.',
  invite: 'Einladen.',
  newMember: 'Neues Mitglied',
  member: 'Mitglieder',
  creator: 'Ersteller',
  change: 'Ändern',
  listSettings: 'Listeneinstellungen',
  addFriend: 'Freund Hinzufügen',
  sureDelete: 'Sicher löschen',
  logout: 'Abmelden',
  pwa: 'Progressive Web App',
  pwa_alert:
    'Howdy! I noticed you are using me as a Website. Did you know, that this is optimized as a PWA? Learn more about it here!',
  pwa_headline:
    'Als PWA (Progressive Web App) kann diese Seite als eine art Smartphone App genutzt werden. Der vorteil davon ist, dass du nicht jedes mal deinen Browser öffnen musst, sondern LiSha wie eine App nutzen kannst.',
  pwa_ios_chrome_title: 'Google Chrome',
  pwa_ios_chrome_text:
    'Im Google Chrome gehst du oben rechts neben der Addresszeile auf das Teilen Symbol und dann fast ganz unten auf "Zum Home-Bildschirm". Als nächstes drückst du auf Hinzufügen und fertig.',
  pwa_ios_safari_title: 'Safari',
  pwa_ios_safari_text:
    'Im Safari gehst du unten in der mitte unter der Addresszeile auf das Teilen Symbol und dann fast ganz unten auf "Zum Home-Bildschirm". Als nächstes drückst du auf Hinzufügen und fertig.',
  changed: 'Geändert!',
  changeError: 'Konnte nicht geändert werden!',
  changelog: 'Changelog',
  profile: 'Profil',
  name: 'Name',
  missing_name_or_email: 'Name oder E-Mail fehlen!',
  options: 'Optionen',
  invited: 'Eingeladen!',
  inviteError: 'Es gab einen Fehler, bitte versuche es erneut!',
  human: 'Mensch',
  theme: 'Design',
  auto: 'Auto',
  dark: 'Dunkel',
  light: 'Hell',
  sharedBy: 'Geteilt von {name}',
  itemCounts: '{count} Elemente',
  itemCount: '1 Element',
  itemCount0: '',
  deleteElements: 'Liste leeren',
  seenItems: 'Abgehackt',
  listoptions: 'Listenoptionen',
  emptyList: 'Diese Liste ist leer.',
  administration: 'Administration',
  users: 'Benutzer',
  administrator: 'Administrator',
  moderator: 'Moderator',
  user: 'Benutzer',
  role: 'Rolle',
  baseSettings: 'Grundeinstellungen',
  listCreatedBy: 'Diese Liste wurde von {name} erstellt.',
  listCreatedByEmail: 'E-Mail: {email}',
  account: 'Account',
  preferedTheme: 'Wähle dein gewünschtes Design',
  addElement: 'Neues Element in Liste hinzufügen',
  listStatistics: 'Statistiken',
  numberOfItems: 'Elemente in Liste',
  numberOfItemsBought: 'davon abgehakt',
  numberOfItemsUnbought: 'davon nicht abgehakt',
  amountOfItems: 'Elemente inkl. Menge in Liste',
  amountOfItemsBought: 'davon abgehakt',
  amountOfItemsUnbought: 'davon nicht abgehakt',
  invitedLists: 'Eingeladen',
  invitedBy: 'Eingeladen von {name}',
  permissions: 'Berechtigungen',
  permissionRead: 'Liste Lesen',
  permissionWrite: 'Elemente Hinzufügen',
  permissionUpdate: 'Elemente Abhaken',
  permissionDelete: 'Elemente Löschen',
  allSwipeable: 'Elemente können verschoben werden.',
  portions: 'Portionen',
  description: 'Beschreibung',
  next: 'Weiter',
}
export default DE
