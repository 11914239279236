import { View } from 'react-native'

import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { NativeStackScreenProps } from '@react-navigation/native-stack'

import { loginUser } from '../API/User'
import Form from '../Components/Form'
import Navigation from '../Components/Navigation'
import useTheme from '../hooks/useTheme'
import Lang from '../lang/Lang'
import { RootStackParamList } from '../RootStackParamList'
import { BodyStyle } from '../Style'

export default function Login(
  nav: NativeStackScreenProps<RootStackParamList, 'Login'>
) {
  const theme = useTheme()

  return (
    <View style={BodyStyle(theme)}>
      <Navigation
        {...nav}
        title={Lang.get('login')}
        links={[{ icon: faUserPlus, to: 'Register', replace: true }]}
      />
      <View>
        <Form<{ email: string; password: string }>
          onSubmit={async ({ email, password }) => {
            if (email !== '' && password !== '') {
              const user = await loginUser(email, password).catch(() => null)

              if (user) {
                await AsyncStorage.setItem('api_key', user.token)
                nav.navigation.replace('Home')

                return [true, '']
              }

              return [false, Lang.get('loginErrorUser')]
            }

            return [false, Lang.get('missing_name_or_email')]
          }}
          fields={[
            {
              name: 'email',
              type: 'email-address',
            },
            {
              name: 'password',
              hideChars: true,
            },
          ]}
          submitText={Lang.get('login')}
        />
      </View>
    </View>
  )
}
