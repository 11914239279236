import { useCallback, useState } from 'react'
import {
  RefreshControl,
  ScrollView,
  useWindowDimensions,
  View,
} from 'react-native'

import { faPlus, faRefresh, faUsers } from '@fortawesome/free-solid-svg-icons'
import { useHeaderHeight } from '@react-navigation/elements'

import BottomNavigation from '../Components/BottomNavigation'
import { LishaElementGroup } from '../Components/Element'
import Navigation, { NavigationProps } from '../Components/Navigation'
import useTheme from '../hooks/useTheme'
import Lang from '../lang/Lang'
import { BodyStyle, mainPadding } from '../Style'

export default function Recipes(nav: NavigationProps<'Recipes'>) {
  const theme = useTheme()
  const [refreshing, setRefreshing] = useState(false)

  function loadData() {}

  const onRefresh = useCallback(() => {
    setRefreshing(true)
    loadData()
  }, [])
  const { height } = useWindowDimensions()
  const headerHeight = useHeaderHeight()

  return (
    <View style={{ ...BodyStyle(theme), paddingRight: 0 }}>
      <Navigation
        back="Home"
        {...nav}
        title={'Recipes'}
        links={[
          {
            icon: faRefresh,
            onClick: loadData,
          },
          {
            icon: faPlus,
            onClick: () => {
              nav.navigation.push('NewRecipe')
            },
          },
        ]}
      />
      <View
        style={{
          // marginBottom: insets.bottom,
          marginTop: -mainPadding,
          marginRight: -mainPadding,
          overflow: 'hidden',
          flex: 1,
          // marginBottom: 50,
        }}
      >
        <ScrollView
          refreshControl={
            <RefreshControl
              title={Lang.get('reloading')}
              tintColor="#fff"
              titleColor="#fff"
              colors={['#fff']}
              refreshing={refreshing}
              onRefresh={onRefresh}
            />
          }
          style={{
            // paddingBottom: insets.bottom,
            paddingRight: mainPadding,
            height: height - headerHeight,
            paddingBottom: 50,
          }}
        >
          <LishaElementGroup
            elements={[
              {
                text: 'Rezept #1',
                onPress: () => {},
                textRight: `4 Portionen`,
                bottomLeft: `Kurzbeschreibung`,
                iconRight: faUsers,
              },
            ]}
          />
        </ScrollView>
      </View>
      <BottomNavigation {...nav} />
    </View>
  )
}
