import axios from 'axios'

import AsyncStorage from '@react-native-async-storage/async-storage'

const API = async () => {
  const apiKey = await AsyncStorage.getItem('api_key')

  return axios.create({
    // baseURL: 'http://192.168.0.29:8080/v1',
    baseURL: 'https://api.lisha-app.com/v1',
    headers: { api_key: apiKey },
  })
}
export default API
