import { useState } from 'react'
import {
  Image,
  Pressable,
  StyleProp,
  Text,
  useWindowDimensions,
  View,
  ViewStyle,
} from 'react-native'

import {
  faArrowRightArrowLeft,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import useTheme from '../hooks/useTheme'
import Lang from '../lang/Lang'
import {
  CenterTextStyle,
  desktopBreakPoint,
  ElementStyle,
  mainPadding,
} from '../Style'
import { Theme } from '../Themes/Theme'

export interface IElementRightButton {
  icon: IconDefinition
  style: StyleProp<ViewStyle>
  onPress: (_id: number) => void
}
export interface IElement {
  amount?: number
  text: string
  textCenter?: boolean
  id?: number
  iconLeft?: IconDefinition
  iconLeftColor?: string
  iconRight?: IconDefinition
  iconRightColor?: string
  imageLeft?: string
  textRight?: string
  onPress?: () => void
  style?: StyleProp<ViewStyle>
  buttonRight?: (IElementRightButton | false)[]
  bottomLeft?: string
  bottomRight?: string
  swipeRight?: (IElementRightButton | false)[]
  swipeLeft?: (IElementRightButton | false)[]
  ignoreSwipeIcon?: boolean
  background?: string
}

export interface IElementGroup {
  elements: IElement[]
  title?: string
}

function ElementContent(props: IElement) {
  const theme = useTheme()

  const elementStyle = ElementStyle(theme)

  const [isPointingDown, setPointingDown] = useState(false)
  const [startX, setStartX] = useState(0)
  const [elementX, setElementX] = useState(0)

  const { width } = useWindowDimensions()

  return (
    <View>
      <View
        onPointerDown={e => {
          if (width >= desktopBreakPoint) return
          // @ts-ignore
          setStartX(e.clientX - elementX)
          setPointingDown(true)
        }}
        onPointerMove={e => {
          e.preventDefault()
          if (!isPointingDown) {
            return
          }
          // @ts-ignore
          const x = e.clientX
          let setX = x - startX
          // if (setX >= 0) setX = 0
          if (setX >= (props.swipeLeft?.length || 0) * 50)
            setX = (props.swipeLeft?.length || 0) * 50

          if (setX <= -(props.swipeRight?.length || 0) * 50)
            setX = -(props.swipeRight?.length || 0) * 50
          setElementX(setX)
        }}
        onPointerUp={e => {
          e.preventDefault()
          setPointingDown(false)
          setElementX(Math.round(elementX / 50) * 50)
        }}
        style={[
          elementStyle.boxInner,
          {
            left: elementX,
            backgroundColor: props.background
              ? props.background
              : Theme.get('backgroundColorLightGrey', theme),
          },
        ]}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <View
            style={[
              elementStyle.leftContent,
              props.textCenter ? elementStyle.boxInnerCenter : {},
            ]}
          >
            {props.amount && props.amount > 1 && (
              <Text style={elementStyle.amount}>{props.amount}</Text>
            )}
            {props.iconLeft && (
              <FontAwesomeIcon
                color={props.iconLeftColor ?? Theme.get('DefaultColor', theme)}
                style={elementStyle.iconLeft}
                icon={props.iconLeft}
              />
            )}
            {props.imageLeft && (
              <Image
                source={{
                  uri: props.imageLeft,
                  height: elementStyle.imageLeft.height,
                  width: elementStyle.imageLeft.width,
                }}
                style={elementStyle.imageLeft}
              />
            )}
            <Text style={elementStyle.mainText}>{props.text}</Text>
          </View>
          {(props.iconRight ||
            props.textRight ||
            props.swipeLeft ||
            props.swipeRight ||
            (props.id && props.buttonRight)) && (
            <View style={elementStyle.rightContent}>
              {props.id &&
                props.buttonRight?.map((btn, idx) => {
                  if (btn === false) return <></>

                  return (
                    <Pressable
                      key={idx}
                      style={[btn.style, elementStyle.rightButton]}
                      onPress={() => btn.onPress(props.id ?? 0)}
                    >
                      <FontAwesomeIcon
                        color={Theme.get(
                          theme === 'dark'
                            ? 'ColorFull'
                            : 'backgroundColorDarkGrey',
                          theme
                        )}
                        size={15}
                        icon={btn.icon}
                        style={{
                          // @ts-ignore
                          outlineStyle: 'none',
                        }}
                      />
                    </Pressable>
                  )
                })}
              {width > desktopBreakPoint &&
                props.swipeLeft &&
                props.swipeLeft.map((btn, idx) => {
                  if (btn === false) return <></>

                  return (
                    <Pressable
                      key={idx}
                      style={[btn.style, elementStyle.rightButton]}
                      onPress={() => btn.onPress(props.id ?? 0)}
                    >
                      <FontAwesomeIcon
                        color={Theme.get(
                          theme === 'dark'
                            ? 'ColorFull'
                            : 'backgroundColorDarkGrey',
                          theme
                        )}
                        size={15}
                        icon={btn.icon}
                        style={{
                          // @ts-ignore
                          outlineStyle: 'none',
                        }}
                      />
                    </Pressable>
                  )
                })}
              {width > desktopBreakPoint &&
                props.swipeRight &&
                props.swipeRight.map((btn, idx) => {
                  if (btn === false) return <></>

                  return (
                    <Pressable
                      key={idx}
                      style={[btn.style, elementStyle.rightButton]}
                      onPress={() => btn.onPress(props.id ?? 0)}
                    >
                      <FontAwesomeIcon
                        color={Theme.get(
                          theme === 'dark'
                            ? 'ColorFull'
                            : 'backgroundColorDarkGrey',
                          theme
                        )}
                        size={15}
                        icon={btn.icon}
                        style={{
                          // @ts-ignore
                          outlineStyle: 'none',
                        }}
                      />
                    </Pressable>
                  )
                })}
              {props.textRight && (
                <Text style={elementStyle.textRight}>{props.textRight}</Text>
              )}
              {props.iconRight && (
                <FontAwesomeIcon
                  style={elementStyle.iconRight}
                  color={props.iconRightColor ?? elementStyle.iconRight.color}
                  size={15}
                  icon={props.iconRight}
                />
              )}
              {(props.swipeLeft || props.swipeRight) &&
                width < desktopBreakPoint &&
                props.ignoreSwipeIcon !== true && (
                  <FontAwesomeIcon
                    style={elementStyle.iconRight}
                    color={elementStyle.iconRight.color}
                    size={15}
                    icon={faArrowRightArrowLeft}
                  />
                )}
            </View>
          )}
        </View>
        {(props.bottomLeft || props.bottomRight) && (
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingVertical: 10,
              borderTopColor:
                theme === 'dark'
                  ? Theme.get('backgroundColorDarkGrey', 'dark')
                  : Theme.get('borderColorLightGrey', 'light'),
              borderTopWidth: 1,
            }}
          >
            <Text
              style={{
                fontSize: 10,
                color: Theme.get('DefaultColorDimmed', theme),
              }}
            >
              {props.bottomLeft}
            </Text>
            <Text
              style={{
                fontSize: 10,
                color: Theme.get('DefaultColorDimmed', theme),
              }}
            >
              {props.bottomRight}
            </Text>
          </View>
        )}
      </View>
      {props.swipeLeft && width < desktopBreakPoint && (
        <View
          style={[
            elementStyle.swipeLeft,
            {
              width: (props.swipeLeft.length || 0) * 50,
            },
          ]}
        >
          {props.swipeLeft.map((btn, idx) => {
            if (btn === false) return <></>

            return (
              <Pressable
                key={`swipe_left${idx}`}
                onPress={() => btn.onPress(0)}
                style={[btn.style, { height: 50, width: 50, borderRadius: 0 }]}
              >
                <FontAwesomeIcon
                  color={Theme.get(
                    theme === 'dark' ? 'ColorFull' : 'backgroundColorDarkGrey',
                    theme
                  )}
                  icon={btn.icon}
                />
              </Pressable>
            )
          })}
        </View>
      )}
      {props.swipeRight && width < desktopBreakPoint && (
        <View
          style={[
            elementStyle.swipeRight,
            {
              width: (props.swipeRight.length || 0) * 50,
            },
          ]}
        >
          {props.swipeRight.map((btn, idx) => {
            if (btn === false) return <></>

            return (
              <Pressable
                key={`swipe_right${idx}`}
                onPress={() => btn.onPress(0)}
                style={[btn.style, { height: 50, width: 50, borderRadius: 0 }]}
              >
                <FontAwesomeIcon
                  color={Theme.get(
                    theme === 'dark' ? 'ColorFull' : 'backgroundColorDarkGrey',
                    theme
                  )}
                  icon={btn.icon}
                />
              </Pressable>
            )
          })}
        </View>
      )}
    </View>
  )
}

export default function LiShaElement(props: IElement) {
  if (props.onPress) {
    return (
      <Pressable style={props.style || {}} onPress={props.onPress}>
        <ElementContent {...props} />
      </Pressable>
    )
  }

  return (
    <View style={props.style || {}}>
      <ElementContent {...props} />
    </View>
  )
}

export function LishaElementGroup(props: IElementGroup) {
  const theme = useTheme()
  const elementStyle = ElementStyle(theme)

  const swiper = props.elements.map(
    e => (e.swipeLeft?.length || 0) > 0 || (e.swipeRight?.length || 0) > 0
  )

  const allSwiperTrue = swiper.filter(e => e !== true).length <= 0
  const allSwiperFalse = swiper.filter(e => e !== false).length <= 0

  return (
    <>
      {props.title && (
        <Text style={elementStyle.subheading}>{props.title}</Text>
      )}
      {props.elements.length === 0 && (
        <Text style={elementStyle.empty}>{Lang.get('emptyList')}</Text>
      )}
      {props.elements.map((element, index) => {
        const style = [elementStyle.box, element.style]
        if (index === 0) {
          style.push(elementStyle.firstGroupElement)
        }
        if (index === props.elements.length - 1) {
          style.push(elementStyle.lastGroupElement)
        }

        return (
          <LiShaElement
            ignoreSwipeIcon={allSwiperTrue && !allSwiperFalse}
            key={`${index}${element.text}`}
            {...element}
            style={style}
          />
        )
      })}
      {allSwiperTrue && !allSwiperFalse && (
        <Text
          style={{
            ...CenterTextStyle(theme),
            paddingBottom: 15,
            height: 30,
            lineHeight: 30,
          }}
        >
          <FontAwesomeIcon
            color={CenterTextStyle(theme).color}
            icon={faArrowRightArrowLeft}
            size={15}
            style={{ paddingRight: mainPadding, height: 30, marginBottom: -10 }}
          />
          {Lang.get('allSwipeable')}
        </Text>
      )}
    </>
  )
}
