import { Pressable, Text, View } from 'react-native'

import useTheme from '../hooks/useTheme'
import { TopBarFlyoutBoxStyle, TopBarFlyoutTextStyle } from '../Style'

interface ITopBarFlyoutItem {
  onClick: () => void
  title: string
}
interface ITopBarFlyout {
  items: ITopBarFlyoutItem[]
}

export default function TopBarFlyout(props: ITopBarFlyout) {
  const theme = useTheme()

  return (
    <View style={TopBarFlyoutBoxStyle(theme)}>
      {props.items.map(item => (
        <Pressable key={item.title} onPress={item.onClick}>
          <Text style={TopBarFlyoutTextStyle(theme)}>{item.title}</Text>
        </Pressable>
      ))}
    </View>
  )
}
