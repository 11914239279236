import { Dimensions, StyleSheet } from 'react-native'
import { EdgeInsets } from 'react-native-safe-area-context'

import { BottomBarInputHeight, Theme } from './Themes/Theme'
import TTheme from './Themes/TTheme'

export const mainPadding = 10
export const windowWidth = () => Dimensions.get('window').width
export const windowHeight = () => Dimensions.get('window').height

export const desktopBreakPoint = 800

export const BodyStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      backgroundColor: Theme.get('backgroundColorDarkGrey', theme),
      paddingHorizontal: mainPadding,
      margin: 0,
      overflow: 'hidden',
      color: Theme.get('DefaultColor', theme),
      display: 'flex',
      flex: 1,
      paddingTop: mainPadding,
    },
  }).box

export const HeadingStyle = (insets: EdgeInsets, theme: TTheme) =>
  StyleSheet.create({
    box: {
      paddingHorizontal: mainPadding,
      paddingVertical: 0,
      paddingTop: insets.top,
      margin: 0,
      marginBottom: 20,
      height: 50 + insets.top,
      lineHeight: 50,
      backgroundColor: Theme.get('backgroundColorLightGrey', theme),
      marginLeft: -mainPadding,
      marginRight: -mainPadding,
      fontWeight: 'bold',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  }).box

export const HeadingTextStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      paddingHorizontal: mainPadding,
      paddingVertical: 0,
      margin: 0,
      marginBottom: 20,
      height: 50,
      lineHeight: 50,
      marginLeft: -mainPadding,
      marginRight: -mainPadding,
      fontWeight: 'bold',
      color: Theme.get('DefaultColor', theme),
    },
  }).box

export const SubHeadingStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      padding: 0,
      margin: 0,
      paddingTop: 5,
      paddingBottom: 5,
      marginBottom: 0,
      color: Theme.get('TextColorLight', theme),
      fontSize: 20,
      height: 40,
      lineHeight: 40,
    },
  }).box
export const SubHeadingBoxStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      ...SubHeadingStyle(theme),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }).box

export const ErrorBoxStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      paddingHorizontal: 20,
      paddingVertical: 0,
      margin: 0,
      marginBottom: 20,
      minHeight: 50,
      lineHeight: 50,
      border: `2px solid ${Theme.get('BorderColorDark', theme)}`,
      borderRadius: 5,
      fontWeight: 'bold',
      backgroundColor: Theme.get('backgroundColorLightGrey', theme),
      color: Theme.get('DefaultColor', theme),
    },
  }).box

export const InputBoxStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      borderColor: Theme.get('borderColorLightGrey', theme),
      borderWidth: 1,
      paddingHorizontal: 10,
      paddingVertical: 0,
      margin: 0,
      marginBottom: 20,
      height: 50,
      borderRadius: 5,
      fontWeight: 'bold',
      backgroundColor: Theme.get('backgroundColorLightGrey', theme),
      color: Theme.get('TextColorLight', theme),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
  }).box

export const SubmitBoxStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      ...InputBoxStyle(theme),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
  }).box

export const SubmitStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      color: Theme.get('DefaultColor', theme),
      fontWeight: 'bold',
      outlineStyle: 'none',
    },
  }).box

export const InputTitleStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      fontWeight: 'bold',
      color: Theme.get('BorderColorDark', theme),
      marginBottom: 6,
    },
  }).box

export const CenterTextStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      color: Theme.get('BorderColorDark', theme),
      textAlign: 'center',
      paddingHorizontal: 10,
      paddingVertical: 0,
    },
  }).box

export const PlainCenterLinkStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      color: Theme.get('BorderColorDark', theme),
      textAlign: 'center',
      height: 40,
      lineHeight: 40,
    },
  }).box

export const BoxLinkBaseStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      color: Theme.get('TextColorDimmed', theme),
      minHeight: 50,
      lineHeight: 20,
      paddingHorizontal: 10,
      paddingVertical: 0,
      overflow: 'hidden',
    },
  }).box

export const BoxLinkStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      ...BoxLinkBaseStyle(theme),
      backgroundColor: Theme.get('backgroundColorLightGrey', theme),
      marginBottom: 1,
    },
  }).box

export const BoxLinkTextStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      ...BoxLinkStyle(theme),
      color: Theme.get('DefaultColor', theme),
      fontWeight: 'bold',
    },
  }).box

export const ItemStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      ...BoxLinkStyle(theme),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  }).box

export const RoundedTopStyle = (_theme: TTheme) =>
  StyleSheet.create({
    box: {
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },
  }).box

export const RoundedBottomStyle = (_theme: TTheme) =>
  StyleSheet.create({
    box: {
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
      marginBottom: 20,
    },
  }).box

export const PlainLinkStyle = (_theme: TTheme) =>
  StyleSheet.create({
    box: {
      color: 'inherit',
    },
  }).box
export const BarLinkStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      textAlign: 'center',
      backgroundColor: Theme.get('backgroundColorTransparent', theme),
      border: 'none',
      height: 50,
      lineHeight: 50,
      paddingHorizontal: 15,
      paddingVertical: 15,
      color: Theme.get('DefaultColor', theme),
      outlineStyle: 'none',
    },
  }).box
export const BackLinkStyle = (_theme: TTheme) =>
  StyleSheet.create({
    box: {
      ...BarLinkStyle,
      paddingLeft: 0,
    },
  }).box

export const BottomBarStyle = (_insets: EdgeInsets) =>
  StyleSheet.create({
    box: {
      height: 67,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }).box
export const BottomBarOuterStyle = (insets: EdgeInsets, theme: TTheme) =>
  StyleSheet.create({
    box: {
      color: Theme.get('TextColorDimmed', theme),
      height: 67 + insets.bottom + (insets.bottom > 0 ? -15 : 0),
      backgroundColor: Theme.get('backgroundColorLightGrey', theme),
      paddingHorizontal: 10,
      paddingVertical: 0,
      marginLeft: -mainPadding,
      marginRight: -mainPadding,
      position: 'absolute',
      bottom: 0,
    },
  }).box

export const BottomBarBaseStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      ...InputBoxStyle(theme),
      marginBottom: 0,
    },
  }).box
export const BottomBarAmountStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      ...BottomBarBaseStyle(theme),
      width: 50,
      marginRight: 1,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      backgroundColor: Theme.get('backgroundColorDarkGrey', theme),
      textAlign: 'center',
    },
  }).box
export const BottomBarNameStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      ...BottomBarBaseStyle(theme),
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      marginRight: 5,
      backgroundColor: Theme.get('backgroundColorDarkGrey', theme),
      width: windowWidth() - 20 - 50 - 5 - 50,
    },
  }).box
export const BottomBarButtonStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      ...BottomBarBaseStyle(theme),
      width: 50,
      textAlign: 'center',
      backgroundColor: Theme.get('backgroundColorToxicGreen', theme),
      color: Theme.get('backgroundColorDarkGrey', theme),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }).box

export const ListContentStyle = (_theme: TTheme) =>
  StyleSheet.create({
    box: {
      paddingVertical: mainPadding,
    },
  }).box

export const ButtonBaseStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      ...BarLinkStyle(theme),
      height: 30,
      lineHeight: 20,
      borderRadius: 3,
      marginTop: 0,
      paddingHorizontal: 15,
      paddingVertical: 15,
    },
  }).box

export const ButtonDefaultStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      ...ButtonBaseStyle(theme),
      backgroundColor: Theme.get('BorderColorDark', theme),
      color: Theme.get(
        theme === 'dark' ? 'textColorSuccess' : 'backgroundColorDarkGrey',
        theme
      ),
      paddingVertical: 0,
      display: 'flex',
      justifyContent: 'center',
    },
  }).box

export const ButtonWarningStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      ...ButtonDefaultStyle(theme),
      backgroundColor: Theme.get('backgroundColorWarning', theme),
      color: Theme.get('textColorWarning', theme),
    },
  }).box

export const ButtonDefaultActiveStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      ...ButtonBaseStyle(theme),
      backgroundColor: Theme.get('textColorSuccess', theme),
      color: Theme.get(
        theme === 'dark' ? 'textColorSuccess' : 'backgroundColorDarkGrey',
        theme
      ),
      paddingVertical: 0,
      display: 'flex',
      justifyContent: 'center',
    },
  }).box
export const ButtonBoughtStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      ...ButtonBaseStyle(theme),
      backgroundColor: Theme.get('strongBackgroundColorSuccess', theme),
      color: Theme.get(
        theme === 'dark' ? 'ColorFull' : 'backgroundColorDarkGrey',
        theme
      ),
      paddingVertical: 0,
      display: 'flex',
      justifyContent: 'center',
    },
  }).box
export const ButtonUnboughtStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      ...ButtonBaseStyle(theme),
      backgroundColor: Theme.get('strongBackgroundColorInfo', theme),
      color: Theme.get(
        theme === 'dark' ? 'ColorFull' : 'backgroundColorDarkGrey',
        theme
      ),
      marginRight: 5,
      paddingVertical: 0,
      display: 'flex',
      justifyContent: 'center',
    },
  }).box
export const ButtonRemoveStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      ...ButtonBaseStyle(theme),
      backgroundColor: Theme.get('strongBackgroundColorDanger', theme),
      color: Theme.get(
        theme === 'dark' ? 'ColorFull' : 'backgroundColorDarkGrey',
        theme
      ),
      paddingVertical: 0,
      display: 'flex',
      justifyContent: 'center',
    },
  }).box

export const TopBarFlyoutBoxStyle = (_theme: TTheme) =>
  StyleSheet.create({
    box: {
      paddingHorizontal: mainPadding,
      position: 'absolute',
      zIndex: 1,
      left: 0,
      right: 0,
    },
  }).box
export const TopBarFlyoutTextStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      color: Theme.get('DefaultColor', theme),
      height: 80,
      lineHeight: 80,
      textAlign: 'right',
      marginBottom: 1,
      backgroundColor: Theme.get('backgroundColorDarkGreyOpaque', theme),
    },
  }).box

export const ElementStyle = (theme: TTheme) =>
  StyleSheet.create({
    subheading: {
      padding: 0,
      margin: 0,
      paddingTop: 5,
      paddingBottom: 5,
      marginBottom: 0,
      fontSize: 20,
      height: 40,
      lineHeight: 40,
      color: Theme.get('DefaultColorDimmed', theme),
    },
    empty: {
      fontSize: 12,
      height: 40,
      lineHeight: 40,
      color: Theme.get('DefaultColorDimmed', theme),
      textAlign: 'center',
    },
    box: {
      minHeight: 50,
      lineHeight: 20,
      paddingHorizontal: 10,
      paddingVertical: 0,
      overflow: 'hidden',
      backgroundColor: Theme.get('backgroundColorLightGrey', theme),
      marginBottom: 1,
      borderColor: Theme.get('borderColorLightGrey', theme),
      borderWidth: 1,
      borderBottomWidth: 0,
    },
    firstGroupElement: {
      marginTop: 5,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },
    lastGroupElement: {
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
      marginBottom: 5,
      borderBottomWidth: 1,
    },
    boxInner: {
      minHeight: 50,
      lineHeight: 20,
      // paddingHorizontal: 10,
      paddingVertical: 0,
      overflow: 'hidden',
      marginBottom: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'relative',
      zIndex: 1,
      marginLeft: -mainPadding,
      marginRight: -mainPadding,
      paddingHorizontal: mainPadding * 2,
    },
    boxInnerCenter: {
      alignItems: 'center',
      justifyContent: 'center',
      flex: -1,
    },
    leftContent: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
    },
    amount: {
      paddingLeft: 0,
      paddingRight: 10,
      lineHeight: 20,
      paddingVertical: 0,
      paddingHorizontal: 0,
      overflow: 'hidden',
      flexWrap: 'wrap',
      alignSelf: 'center',
      color: Theme.get('DefaultColorDimmed', theme),
    },
    iconLeft: {
      minHeight: 50,
      lineHeight: 20,
      paddingHorizontal: 10,
      paddingVertical: 0,
      overflow: 'hidden',
      paddingLeft: 0,
      outlineStyle: 'none',
    },
    imageLeft: {
      marginTop: (50 - 25) / 2,
      marginRight: 10,
      height: 25,
      width: 25,
    },
    mainText: {
      // minHeight: 50,
      lineHeight: 20,
      paddingVertical: 15,
      fontWeight: 'bold',
      paddingHorizontal: 0,
      flex: 1,
      overflow: 'hidden',
      flexWrap: 'wrap',
      alignSelf: 'center',
      color: Theme.get('DefaultColor', theme),
    },
    rightContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    rightButton: {
      marginLeft: 10,
    },
    textRight: {
      minHeight: 50,
      paddingHorizontal: 10,
      paddingVertical: 0,
      overflow: 'hidden',
      paddingRight: 0,
      paddingLeft: 10,
      lineHeight: 50,
      color: Theme.get('DefaultColorDimmed', theme),
    },
    iconRight: {
      minHeight: 50,
      lineHeight: 20,
      paddingHorizontal: 10,
      paddingVertical: 0,
      overflow: 'hidden',
      paddingRight: 0,
      paddingLeft: 10,
      outlineStyle: 'none',
      color: Theme.get('DefaultColorDimmed', theme),
    },
    swipeLeft: {
      top: 0,
      bottom: 1,
      position: 'absolute',
      left: -mainPadding,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    },
    swipeRight: {
      top: 0,
      bottom: 1,
      position: 'absolute',
      right: -mainPadding,
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      display: 'flex',
      flexDirection: 'row',
    },
  })

export const InputBarStyle = (insets: EdgeInsets, theme: TTheme) =>
  StyleSheet.create({
    box: {
      height:
        BottomBarInputHeight +
        17 +
        insets.bottom +
        (insets.bottom > 0 ? -15 : 0),
      backgroundColor: Theme.get('backgroundColorLightGrey', theme),
      paddingHorizontal: 10,
      paddingVertical: 0,
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      borderTopColor: Theme.get(
        theme === 'light' ? 'borderColorLightGrey' : 'backgroundColorDarkGrey',
        theme
      ),
      borderTopWidth: 1,
    },
    innerBox: {
      height: BottomBarInputHeight + 17,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    amount: {
      borderColor: Theme.get('borderColorLightGrey', theme),
      borderWidth: 1,
      borderRightWidth: 0,
      paddingHorizontal: 10,
      paddingVertical: 0,
      margin: 0,
      height: BottomBarInputHeight,
      borderRadius: BottomBarInputHeight / 2,
      fontWeight: 'bold',
      color: Theme.get('TextColorLight', theme),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 0,
      width: BottomBarInputHeight,
      // marginRight: 1,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      backgroundColor: Theme.get(
        theme === 'dark' ? 'backgroundColorDarkGrey' : 'borderColorLightGrey',
        theme
      ),
      textAlign: 'center',
      outlineStyle: 'none',
    },
    text: {
      borderColor: Theme.get('borderColorLightGrey', theme),
      borderWidth: 1,
      paddingHorizontal: 10,
      paddingVertical: 0,
      margin: 0,
      height: BottomBarInputHeight,
      borderRadius: BottomBarInputHeight / 2,
      fontWeight: 'bold',
      color: Theme.get('TextColorLight', theme),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      marginRight: mainPadding,
      backgroundColor: Theme.get(
        theme === 'dark' ? 'backgroundColorDarkGrey' : 'borderColorLightGrey',
        theme
      ),
      flex: 1,
      outlineStyle: 'none',
    },
    button: {
      borderColor: Theme.get('borderColorLightGrey', theme),
      borderWidth: 1,
      paddingHorizontal: 10,
      paddingVertical: 0,
      margin: 0,
      height: BottomBarInputHeight,
      borderRadius: BottomBarInputHeight / 2,
      fontWeight: 'bold',
      flexDirection: 'row',
      marginBottom: 0,
      width: BottomBarInputHeight,
      textAlign: 'center',
      backgroundColor: Theme.get(
        theme === 'dark' ? 'backgroundColorDarkGrey' : 'borderColorLightGrey',
        theme
      ),
      color: Theme.get(
        theme === 'dark' ? 'DefaultColor' : 'backgroundColorDarkGrey',
        theme
      ),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonActive: {
      backgroundColor: Theme.get('backgroundColorToxicGreen', theme),
    },
  })

export const OptionsFlyoutStyle = (theme: TTheme) =>
  StyleSheet.create({
    darkenPanel: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      backgroundColor: Theme.get('ColorFull50', theme),
      overflow: 'hidden',
    },
    box: {
      position: 'absolute',
      zIndex: 1,
      bottom: 0,
      left: 10,
      right: 10,
      paddingHorizontal: 10,
      backgroundColor: Theme.get('ColorFull', theme),
      borderTopRightRadius: 5,
      borderTopLeftRadius: 5,
    },
    haedline: {
      color: Theme.get('DefaultColor', theme),
      fontSize: 15,
      fontWeight: 'bold',
      paddingTop: 10,
      paddingBottom: 5,
    },
  })

export const GroupStyle = (theme: TTheme) =>
  StyleSheet.create({
    box: {
      padding: mainPadding,
      borderWidth: 1,
      borderColor: Theme.get('borderColorLightGrey', theme),
      borderRadius: 5,
      overflow: 'visible',
      marginTop: mainPadding,
      marginBottom: mainPadding,
    },
    textBox: {
      marginLeft: -mainPadding / 2,
      marginTop: -mainPadding - 20 / 2,
      position: 'relative',
      backgroundColor: Theme.get('backgroundColorDarkGrey', theme),
      paddingLeft: mainPadding / 2,
      paddingRight: mainPadding,
      alignSelf: 'baseline',
    },
    text: {
      color: Theme.get('DefaultColorDimmed', theme),
      height: 20,
      fontWeight: 'bold',
      lineHeight: 20,
    },
  })

export const BottomNavigationStyle = (theme: TTheme, insets: EdgeInsets) =>
  StyleSheet.create({
    outerBox: {
      paddingBottom: insets.bottom,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      borderTopColor: Theme.get(
        theme === 'dark' ? 'backgroundColorDarkGrey' : 'BorderColorDark',
        theme
      ),
      borderTopWidth: 1,
      backgroundColor: Theme.get('backgroundColorLightGrey', theme),
    },
    box: {
      height: 50,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    button: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      // paddingHorizontal: mainPadding * 1.5,
      width: 70,
    },
    buttonActive: {
      backgroundColor: Theme.get('backgroundColorLightGrey', theme),
      height: 60,
      marginTop: -10,
      borderRadius: 5,
      // borderColor: Theme.get('backgroundColorDarkGrey', theme),
      // borderWidth: 1,
      shadowColor: Theme.get(
        theme === 'dark' ? 'backgroundColorDarkGrey' : 'BorderColorDark',
        theme
      ),
      shadowOffset: { width: 0, height: 0 },
      shadowOpacity: 1,
      shadowRadius: 5,
    },
    buttonInActive: { opacity: 0.5 },
    icon: { color: Theme.get('DefaultColor', theme), position: 'absolute' },
    iconActive: { top: 13 },
    text: {
      color: Theme.get('DefaultColor', theme),
      position: 'absolute',
      bottom: 10,
      fontSize: 10,
      opacity: 0.5,
    },
  })
