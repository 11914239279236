import { useEffect, useState } from 'react'
import { Pressable, Text, TextInput, View } from 'react-native'

import { faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import useTheme from '../hooks/useTheme'
import ILang from '../lang/ILang'
import Lang from '../lang/Lang'
import { InputBoxStyle, InputTitleStyle, mainPadding, SubmitBoxStyle, SubmitStyle } from '../Style'
import { Theme } from '../Themes/Theme'

export interface IFormInput {
  name: keyof ILang
  value?: string
  type?: 'email-address'
  hideChars?: boolean
}

export interface IForm<T> {
  onSubmit: (_data: T) => Promise<[boolean, string]>
  fields: IFormInput[]
  submitText: string
}

export default function Form<T>(props: IForm<T>) {
  const [data, setData] = useState(props.fields)
  const [feedback, setFeedback] = useState<{
    hasFeedback: boolean
    success: boolean
    feedback: string
  }>({
    hasFeedback: false,
    success: false,
    feedback: '',
  })

  async function onSubmit() {
    const ret: T = {} as T

    data.forEach(item => {
      // @ts-ignore
      ret[item.name] = item.value
    })

    const [success, msg] = await props.onSubmit(ret)
    setFeedback({
      hasFeedback: true,
      success,
      feedback: msg,
    })
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (feedback.hasFeedback) {
        setFeedback({ feedback: '', hasFeedback: false, success: false })
      }
    }, (feedback.success ? 1 : 3) * 1000)

    return () => clearTimeout(timeout)
  }, [feedback.feedback])

  const theme = useTheme()

  return (
    <>
      {props.fields.map((field, idx) => (
        <View key={`${theme}${idx}`}>
          <Text style={InputTitleStyle(theme)}>{Lang.get(field.name)}</Text>
          <TextInput
            style={InputBoxStyle(theme)}
            onChangeText={text => {
              const newData = [...data]
              newData[idx].value = text
              setData(newData)
            }}
            defaultValue={field.value}
            onSubmitEditing={onSubmit}
            keyboardType={field.type ?? 'default'}
            secureTextEntry={field.hideChars}
            autoFocus={idx === 0}
          />
        </View>
      ))}
      {feedback.hasFeedback && feedback.success && (
        <Pressable
          key={theme}
          style={{
            ...SubmitBoxStyle(theme),
            backgroundColor: Theme.get('backgroundColorSuccess', theme),
          }}
        >
          <FontAwesomeIcon
            style={{ ...SubmitStyle(theme), paddingRight: mainPadding }}
            color={Theme.get('DefaultColor', theme)}
            icon={faCheck}
          />
          <Text style={SubmitStyle(theme)}>{feedback.feedback}</Text>
        </Pressable>
      )}
      {feedback.hasFeedback && !feedback.success && (
        <Pressable
          key={theme}
          style={{
            ...SubmitBoxStyle(theme),
            backgroundColor: Theme.get('backgroundColorDanger', theme),
          }}
        >
          <FontAwesomeIcon
            key={theme}
            style={{ ...SubmitStyle(theme), paddingRight: mainPadding }}
            color={Theme.get('DefaultColor', theme)}
            icon={faExclamationTriangle}
          />
          <Text style={SubmitStyle(theme)}>{feedback.feedback}</Text>
        </Pressable>
      )}
      {!feedback.hasFeedback && (
        <Pressable onPress={onSubmit} style={SubmitBoxStyle(theme)} key={theme}>
          <Text style={SubmitStyle(theme)} key={theme}>
            {props.submitText ?? Lang.get('change')}
          </Text>
        </Pressable>
      )}
    </>
  )
}
