import { updateList } from '../API/List'
import Form from '../Components/Form'
import IAPIListDetails from '../interfaces/IAPIListDetails'
import Lang from '../lang/Lang'

export default function FListSettings(list: IAPIListDetails) {
  return (
    <Form<{ listname: string }>
      fields={[{ name: 'listname', value: list.name }]}
      submitText={Lang.get('change')}
      onSubmit={async ({ listname }) => {
        const changed = await updateList(list.id, listname).catch(() => null)
        if (!changed) return [false, Lang.get('changeError')]

        return [true, Lang.get('changed')]
      }}
    />
  )
}
