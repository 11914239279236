import { View } from 'react-native'

import { faPlus, faRefresh } from '@fortawesome/free-solid-svg-icons'

import Form from '../Components/Form'
import Navigation, { NavigationProps } from '../Components/Navigation'
import useTheme from '../hooks/useTheme'
import Lang from '../lang/Lang'
import { BodyStyle, mainPadding } from '../Style'

export default function NewRecipe(nav: NavigationProps<'NewRecipe'>) {
  const theme = useTheme()

  return (
    <View style={{ ...BodyStyle(theme), paddingRight: 0 }}>
      <Navigation back="Home" {...nav} title={'New Recipe'} />
      <View
        style={{
          overflow: 'hidden',
          flex: 1,
        }}
      >
        <Form<{ name: string; portions: string; description: string }>
          fields={[
            { name: 'name' },
            { name: 'portions' },
            { name: 'description' },
          ]}
          onSubmit={async data => {
            console.log('ok', data)
            nav.navigation.replace('Recipe', { recipeId: 1 })

            return [true, '']
          }}
          submitText={Lang.get('next')}
        />
        {/* <LishaElementGroup
            elements={[
              {
                text: 'Rezept #1',
                onPress: () => {},
                textRight: `4 Portionen`,
                bottomLeft: `Kurzbeschreibung`,
                iconRight: faUsers,
              },
            ]}
          /> */}
      </View>
    </View>
  )
}
