import 'react-native-gesture-handler'

import { StatusBar } from 'expo-status-bar'
import React from 'react'
import { View } from 'react-native'
import {
  initialWindowMetrics,
  SafeAreaProvider,
  useSafeAreaInsets,
} from 'react-native-safe-area-context'

import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'

import useTheme from './src/hooks/useTheme'
import Changelog from './src/pages/Changelog'
import Home from './src/pages/Home'
import ListSeen from './src/pages/ListSeen'
import NewRecipe from './src/pages/NewRecipe'
import {
  List,
  ListSettings,
  Login,
  NewList,
  PWA,
  Register,
  User,
} from './src/pages/Pages'
import Recipe from './src/pages/Recipe'
import Recipes from './src/pages/Recipes'
import Users from './src/pages/Users'
import { RootStackParamList } from './src/RootStackParamList'
import { ThemeProvider } from './src/ThemeContext'
import { Theme } from './src/Themes/Theme'

const Stack = createStackNavigator<RootStackParamList>()

type Test = keyof RootStackParamList

const linking: {
  prefixes: string[]
  config: { screens: { [_key in Test]: string } }
} = {
  prefixes: ['https://app.lisha-app.com'],
  config: {
    screens: {
      Home: '',
      Login: 'login',
      Register: 'register',
      NewList: 'new_list',
      List: 'list/:listId',
      ListSeen: 'list/:listId/seen',
      ListSettings: 'list/:listId/settings',
      User: 'user',
      Users: 'users',
      PWA: 'pwa',
      Changelog: 'changelog',
      Recipes: 'recipes',
      Logout: 'logout',
      NewRecipe: 'recipe/new',
      Recipe: 'recipe/:recipeId',
    },
  },
}

function Stck() {
  const insets = useSafeAreaInsets()
  const theme = useTheme()
  // const [height, setHeight] = useState(0)
  // const { width, height: h } = useWindowDimensions()

  // useEffect(() => {
  //   const { height: h1 } = Dimensions.get('window')

  //   setHeight(h1)
  // }, [h])

  return (
    <>
      <View
        style={{
          backgroundColor: '#111111',
          height: insets.top,
          position: 'absolute',
          left: 0,
          right: 0,
          zIndex: 1,
        }}
      ></View>
      <StatusBar style={theme === 'dark' ? 'light' : 'dark'} />
      <Stack.Navigator initialRouteName="Home">
        <Stack.Screen name="Home" component={Home} />
        <Stack.Screen name="Login" component={Login} />
        <Stack.Screen name="Register" component={Register} />
        <Stack.Screen name="NewList" component={NewList} />
        <Stack.Screen name="List" component={List} />
        <Stack.Screen name="ListSettings" component={ListSettings} />
        <Stack.Screen name="User" component={User} />
        <Stack.Screen name="PWA" component={PWA} />
        <Stack.Screen name="Changelog" component={Changelog} />
        <Stack.Screen name="ListSeen" component={ListSeen} />
        <Stack.Screen name="Users" component={Users} />
        <Stack.Screen name="Recipes" component={Recipes} />
        <Stack.Screen name="NewRecipe" component={NewRecipe} />
        <Stack.Screen name="Recipe" component={Recipe} />
      </Stack.Navigator>
      {/* <View
        style={{
          backgroundColor: '#fff',
          position: 'absolute',
          zIndex: 100,
          top: 0,
          left: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height,
          width,
        }}
      >
        <Text style={{ fontSize: 20 }}>
          window {width} x {height}
        </Text>
        <Text style={{ fontSize: 20 }}>({navigator.userAgent})</Text>
        <BottomInputBar onChange={() => {}} />
      </View> */}
    </>
  )
}

function Nav() {
  const theme = useTheme()

  return (
    <NavigationContainer linking={linking}>
      <SafeAreaProvider
        style={{
          flex: 1,
          backgroundColor: Theme.get('backgroundColorDarkGrey', theme),
        }}
        initialMetrics={initialWindowMetrics}
      >
        <Stck />
      </SafeAreaProvider>
    </NavigationContainer>
  )
}

export default function App() {
  return (
    <ThemeProvider>
      <Nav />
    </ThemeProvider>
  )
}
