import { useCallback, useEffect, useState } from 'react'
import { RefreshControl, ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import {
  faCheck,
  faCheckSquare,
  faCog,
  faEllipsisVertical,
  faRefresh,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { NativeStackScreenProps } from '@react-navigation/native-stack'

import {
  addListItem,
  deleteBoughtListItems,
  deleteListItem,
  deleteListItems,
  getListItems,
  updateListItem,
} from '../API/Items'
import BottomInputBar from '../Components/BottomInputBar'
import { LishaElementGroup } from '../Components/Element'
import Navigation from '../Components/Navigation'
import OptionsFlyout from '../Components/OptionsFylout'
import SidebarNavigation from '../Components/SidebarNavigation'
import TopBarFlyout from '../Components/TopBarFlyout'
import useTheme from '../hooks/useTheme'
import IAPIItemsResponse from '../interfaces/IAPIItemsResponse'
import Lang from '../lang/Lang'
import { RootStackParamList } from '../RootStackParamList'
import {
  BodyStyle,
  ButtonBoughtStyle,
  ButtonRemoveStyle,
  InputBarStyle,
  ListContentStyle,
  mainPadding,
} from '../Style'

export default function List(
  nav: NativeStackScreenProps<RootStackParamList, 'List'>
) {
  const { listId } = nav.route.params
  const insets = useSafeAreaInsets()

  const [list, setList] = useState<IAPIItemsResponse>({
    id: -1,
    name: '',
    items: { bought: [], unbought: [] },
    user: 0,
    admin: '',
    isAdmin: true,
    access: {
      accepted: false,
      read: false,
      write: false,
      delete: false,
      update: false,
    },
  })

  const [refreshing, setRefreshing] = useState(false)
  const [autoFocus, setAutoFocus] = useState(false)

  /**
   *
   * @returns {void}
   */
  async function loadData() {
    if (!listId) return
    await getListItems(listId).then(setList)
    setRefreshing(false)
  }

  const onRefresh = useCallback(() => {
    setRefreshing(true)
    loadData()
  }, [])

  useEffect(() => {
    if (!listId) return
    loadData()

    const interval = setInterval(() => {
      getListItems(listId).then(setList)
    }, 3 * 1000)

    return () => clearInterval(interval)
  }, [listId])

  const [isDeleteAllOpen, setDeleteAllOpen] = useState(false)
  const [isDeleteBoughtOpen, setDeleteBoughtOpen] = useState(false)

  const [optionsOpen, setOptionsOpen] = useState(false)

  useEffect(() => {
    setDeleteAllOpen(false)
    setDeleteBoughtOpen(false)
  }, [list])
  const theme = useTheme()

  return (
    <SidebarNavigation {...nav}>
      <View style={BodyStyle(theme)}>
        <Navigation
          {...nav}
          title={list.name}
          subTitle={
            list.isAdmin === false
              ? Lang.get('sharedBy', { name: list.admin })
              : ''
          }
          back="Home"
          links={(() => {
            const ret = [
              {
                icon: faRefresh,
                onClick: () => {
                  loadData()
                },
              },
            ]

            if (
              list.isAdmin ||
              list.access.delete ||
              (list.access.update && list.items.bought.length > 0)
            )
              ret.push({
                icon: faEllipsisVertical,
                onClick: () => setOptionsOpen(e => !e),
              })

            return ret
          })()}
        />
        {isDeleteAllOpen && (
          <TopBarFlyout
            items={[
              {
                title: Lang.get('sureDelete'),
                onClick: () => {
                  deleteListItems(list.id).then(setList)
                },
              },
            ]}
          />
        )}
        {isDeleteBoughtOpen && (
          <TopBarFlyout
            items={[
              {
                title: Lang.get('sureDelete'),
                onClick: () => {
                  deleteBoughtListItems(list.id).then(setList)
                },
              },
            ]}
          />
        )}
        <View
          style={{
            marginTop: -mainPadding,
            overflow: 'hidden',
            flex: 1,
          }}
        >
          <ScrollView
            refreshControl={
              <RefreshControl
                title={Lang.get('reloading')}
                tintColor="#fff"
                titleColor="#fff"
                colors={['#fff']}
                refreshing={refreshing}
                onRefresh={onRefresh}
              />
            }
          >
            <View
              style={[
                ListContentStyle(theme),
                {
                  paddingBottom: InputBarStyle(insets, theme).box.height,
                },
              ]}
            >
              <LishaElementGroup
                elements={list.items.unbought.map(e => ({
                  text: e.name,
                  id: e.id,
                  amount: e.amount,
                  buttonRight: [
                    list.access.update && {
                      icon: faCheck,
                      onPress: () => {
                        updateListItem(list.id, e.name, e.amount, true).then(
                          loadData
                        )
                      },
                      style: ButtonBoughtStyle(theme),
                    },
                  ],
                  swipeRight: [
                    list.access.delete && {
                      icon: faTrashCan,
                      onPress: () => {
                        deleteListItem(list.id, e.id).then(loadData)
                      },
                      style: ButtonRemoveStyle(theme),
                    },
                  ],
                }))}
              />
            </View>
          </ScrollView>
        </View>
        {optionsOpen && (
          <OptionsFlyout
            title="listoptions"
            elements={[
              list.access.delete && {
                text: Lang.get('deleteElements'),
                iconLeft: faTrash,
                swipeRight: [
                  {
                    icon: faTrash,
                    onPress: () => {
                      setOptionsOpen(false)
                      deleteListItems(list.id).then(setList)
                    },
                    style: ButtonRemoveStyle(theme),
                  },
                ],
              },
              list.isAdmin && {
                text: Lang.get('listSettings'),
                onPress: () => {
                  setOptionsOpen(false)
                  nav.navigation.push('ListSettings', { listId: list.id })
                },
                iconLeft: faCog,
              },
              list.access.update &&
                list.items.bought.length > 0 && {
                  text: Lang.get('seenItems'),
                  onPress: () => {
                    setOptionsOpen(false)
                    nav.navigation.push('ListSeen', { listId: list.id })
                  },
                  iconLeft: faCheckSquare,
                },
            ]}
            onGone={() => {
              setOptionsOpen(false)
            }}
          />
        )}
        {list.access.write && (
          <BottomInputBar
            onChange={(name, amount) => {
              setAutoFocus(true)
              addListItem(list.id, name, parseInt(amount, 10)).then(e => {
                setList(e)
              })
            }}
            autoFocus={autoFocus}
          />
        )}
      </View>
    </SidebarNavigation>
  )
}
