import IAPIList from '../interfaces/IAPIList'
import IAPIListResponse from '../interfaces/IAPIListResponse'
import IAPIMsg from '../interfaces/IAPIMsg'
import API from './API'

export const getLists = async () =>
  (await API()).get<IAPIListResponse>('/list').then(e => e.data)

export const createList = async (name: string) =>
  (await API()).post<IAPIList>('/list', { name }).then(e => e.data)

export const updateList = async (id: number, name: string) =>
  (await API()).put<IAPIMsg>(`/list/${id}`, { name }).then(e => e.data)

export const deleteList = async (id: number) =>
  (await API()).delete<IAPIMsg>(`/list/${id}`).then(e => e.data)
