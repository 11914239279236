import { useEffect, useRef, useState } from 'react'
import { Pressable, TextInput, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import useTheme from '../hooks/useTheme'
import Lang from '../lang/Lang'
import { InputBarStyle } from '../Style'
import { Theme } from '../Themes/Theme'

export default function BottomInputBar(props: {
  onChange: (_name: string, _amount: string) => void
  autoFocus?: boolean
}) {
  const theme = useTheme()

  const insets = useSafeAreaInsets()
  const inputBarStyle = InputBarStyle(insets, theme)
  const [name, setName] = useState('')
  const [amount, setAmount] = useState('1')
  // const [hasFocus, setFocus] = useState(false)

  const ref = useRef<TextInput>(null)

  function onNewItem() {
    if (name.trim() !== '' && amount.trim() !== '') {
      props.onChange(name.trim(), amount.trim())
      setAmount('1')
      setName('')
    } else {
      setAmount(e => e.trim())
      setName(e => e.trim())
    }
    ref.current?.focus()
  }
  useEffect(() => {
    if (props.autoFocus === true) {
      ref.current?.focus()
    }
  }, [props.autoFocus])

  return (
    <View
      style={[
        inputBarStyle.box,
        // theme === 'dark'
        //   ? {}
        //   : { backgroundColor: 'transparent', borderTopColor: 'transparent' },
      ]}
    >
      <View style={inputBarStyle.innerBox}>
        {amount !== '' && name !== '' && (
          <TextInput
            style={[inputBarStyle.amount]}
            keyboardType="number-pad"
            value={amount}
            onChangeText={setAmount}
            onSubmitEditing={onNewItem}
          />
        )}
        <TextInput
          value={name}
          style={[
            inputBarStyle.text,
            amount === '' || name === ''
              ? {
                  marginRight: 0,
                  borderTopLeftRadius: 25,
                  borderBottomLeftRadius: 25,
                  paddingLeft: 15,
                }
              : {},
          ]}
          placeholder={Lang.get('addElement')}
          onChangeText={setName}
          ref={ref}
          // autoFocus={true}
          onFocus={() => {
            console.log('focus')
            try {
              window.scrollTo(0, 0)
              setTimeout(() => {
                window.scrollTo(0, 0)
              }, 300)
            } catch (e) {
              /* empty */
            }
          }}
          onSubmitEditing={onNewItem}
        />
        {amount !== '' && name !== '' && (
          <Pressable
            style={[
              inputBarStyle.button,
              amount !== '' && name !== '' && inputBarStyle.buttonActive,
            ]}
            onPress={onNewItem}
          >
            <FontAwesomeIcon
              icon={faCheck}
              size={20}
              style={{
                // @ts-ignore
                outlineStyle: 'none',
              }}
              color={
                amount !== '' && name !== ''
                  ? Theme.get('DefaultColor', theme)
                  : Theme.get(
                      theme === 'dark'
                        ? 'DefaultColor'
                        : 'backgroundColorDarkGrey',
                      theme
                    )
              }
            />
          </Pressable>
        )}
      </View>
    </View>
  )
}
