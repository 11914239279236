import IColor from './IColor'

const Light: IColor = {
  backgroundColorDarkGrey: '#FFFFFF',
  backgroundColorLightGrey: '#FFFFFF',
  borderColorLightGrey: '#eee',
  backgroundColorTransparent: 'transparent',
  backgroundColorToxicGreen: '#4ae53a',

  backgroundColorSuccess: '#a0faa4',
  textColorSuccess: '#000000',
  strongBackgroundColorSuccess: '#3ca341',
  strongTextColorSuccess: '#000000',

  backgroundColorInfo: '#a0eefa',
  textColorInfo: '#000000',
  strongBackgroundColorInfo: '#3ca1a3',
  strongTextColorInfo: '#000000',

  backgroundColorDanger: '#faa0a0',
  textColorDanger: '#000000',
  strongBackgroundColorDanger: '#a33c3c',
  strongTextColorDanger: '#000000',

  backgroundColorWarning: '#fadda0',
  textColorWarning: '#000000',
  strongBackgroundColorWarning: '#a36c3c',
  strongTextColorWarning: '#000000',

  backgroundColorDarkGreyOpaque: '#FFFFFFEE',
  DefaultColor: '#000000',
  DefaultColorDimmed: '#00000080',

  TextColorLight: '#595959',
  BorderColorDark: '#bababa',
  TextColorDimmed: '#6b6b6b',
  ColorFull: '#FFFFFF',
  ColorFull50: '#FFFFFF80',
}

export default Light
