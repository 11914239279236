import { View } from 'react-native'

import useTheme from '../hooks/useTheme'
import { mainPadding } from '../Style'
import { Theme } from '../Themes/Theme'

export default function Hr() {
  const theme = useTheme()

  return (
    <View
      style={{
        backgroundColor: Theme.get('borderColorLightGrey', theme),
        height: 1,
        marginBottom: mainPadding,
      }}
    ></View>
  )
}
