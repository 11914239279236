import { Pressable, Text } from 'react-native'

import useTheme from '../hooks/useTheme'
import IListFragment from '../interfaces/IListFragment'
import Lang from '../lang/Lang'
import { InputBoxStyle } from '../Style'
import { Theme } from '../Themes/Theme'

export default function FListDelete(
  lst: IListFragment & { isAdmin: boolean; setFlyout: (_s: boolean) => void }
) {
  const theme = useTheme()

  return (
    <>
      <Pressable
        onPress={() => lst.setFlyout(true)}
        style={{
          ...InputBoxStyle(theme),
          marginTop: 20,
          backgroundColor: Theme.get('backgroundColorDanger', theme),
        }}
      >
        <Text style={{ color: Theme.get('textColorDanger', theme) }}>
          {Lang.get(lst.isAdmin ? 'deleteList' : 'leaveList')}
        </Text>
      </Pressable>
    </>
  )
}
