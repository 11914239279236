import { useContext, useEffect, useState } from 'react'
import { Appearance, Text, View } from 'react-native'

import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { faMoon, faSignOut, faSun } from '@fortawesome/free-solid-svg-icons'
import AsyncStorage from '@react-native-async-storage/async-storage'

import { logoutUser, selfUser, updateUser } from '../API/User'
import BottomNavigation from '../Components/BottomNavigation'
import { LishaElementGroup } from '../Components/Element'
import Form from '../Components/Form'
import Group from '../Components/Group'
import Navigation, { NavigationProps } from '../Components/Navigation'
import SidebarNavigation from '../Components/SidebarNavigation'
import IAPIUser from '../interfaces/IAPIUser'
import Lang from '../lang/Lang'
import {
  BodyStyle,
  ButtonDefaultActiveStyle,
  ButtonDefaultStyle,
} from '../Style'
import { ThemeContext } from '../ThemeContext'
import { Theme } from '../Themes/Theme'
import TTheme from '../Themes/TTheme'

export default function User(nav: NavigationProps<'User'>) {
  const [user, setUser] = useState<IAPIUser>()
  const [chosenTheme, setChosenTheme] = useState<TTheme | 'auto'>()

  useEffect(() => {
    selfUser().then(setUser)
    AsyncStorage.getItem('theme')
      .catch(() => 'auto')
      .then(t => {
        if (t === 'dark') setChosenTheme('dark')
        if (t === 'light') setChosenTheme('light')
        if (t === 'auto') setChosenTheme('auto')
      })
  }, [])

  if (!user) {
    return <Text>Loading...</Text>
  }

  // const theme = useTheme()
  const { theme, setTheme } = useContext(ThemeContext)

  let chosenThemeSelection: TTheme | 'auto' = 'auto'
  if (chosenTheme === 'auto') chosenThemeSelection = 'auto'
  if (chosenTheme === 'dark') chosenThemeSelection = 'dark'
  if (chosenTheme === 'light') chosenThemeSelection = 'light'

  return (
    <SidebarNavigation {...nav}>
      <View style={BodyStyle(theme)}>
        <Navigation
          back="Home"
          {...nav}
          subTitle={user.name}
          title={Lang.get('profile')}
        />
        <Group title={Lang.get('theme')}>
          <LishaElementGroup
            elements={[
              {
                text: Lang.get('theme'),
                id: -1,
                buttonRight: [
                  {
                    icon: faCircle,
                    onPress: () => {
                      const colorScheme = Appearance.getColorScheme()
                      if (colorScheme) {
                        setTheme(colorScheme)
                      }
                      setChosenTheme('auto')
                      AsyncStorage.setItem('theme', 'auto')
                    },
                    style: (chosenThemeSelection === 'auto'
                      ? ButtonDefaultActiveStyle
                      : ButtonDefaultStyle)(theme),
                  },
                  {
                    icon: faSun,
                    onPress: () => {
                      setChosenTheme('light')
                      setTheme('light')
                      AsyncStorage.setItem('theme', 'light')
                    },
                    style: (chosenThemeSelection === 'light'
                      ? ButtonDefaultActiveStyle
                      : ButtonDefaultStyle)(theme),
                  },
                  {
                    icon: faMoon,
                    onPress: () => {
                      setChosenTheme('dark')
                      setTheme('dark')
                      AsyncStorage.setItem('theme', 'dark')
                    },
                    style: (chosenThemeSelection === 'dark'
                      ? ButtonDefaultActiveStyle
                      : ButtonDefaultStyle)(theme),
                  },
                ],
              },
            ]}
          />
        </Group>
        <Group title={Lang.get('account')}>
          <Form<{ name: string; email: string }>
            onSubmit={async usr => {
              if (usr.name !== '' && usr.email !== '') {
                await updateUser(usr.email, usr.name)
                await selfUser().then(setUser)

                return [true, Lang.get('changed')]
              }

              return [false, Lang.get('missing_name_or_email')]
            }}
            fields={[
              {
                name: 'name',
                value: user.name,
              },
              {
                name: 'email',
                value: user.email,
                type: 'email-address',
              },
            ]}
            submitText={Lang.get('change')}
          />
        </Group>

        <LishaElementGroup
          elements={[
            {
              text: Lang.get('logout'),
              textCenter: true,
              iconLeft: faSignOut,
              background: Theme.get('backgroundColorDanger', theme),
              onPress: () => {
                logoutUser().then(() => {
                  AsyncStorage.removeItem('api_key').then(() => {
                    nav.navigation.popToTop()
                    nav.navigation.replace('Login')
                  })
                })
              },
            },
          ]}
        />
      </View>
      <BottomNavigation {...nav} />
    </SidebarNavigation>
  )
}
