import { View } from 'react-native'

import { faSignIn } from '@fortawesome/free-solid-svg-icons'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { NativeStackScreenProps } from '@react-navigation/native-stack'

import { createUser } from '../API/User'
import Form from '../Components/Form'
import Navigation from '../Components/Navigation'
import useTheme from '../hooks/useTheme'
import Lang from '../lang/Lang'
import { RootStackParamList } from '../RootStackParamList'
import { BodyStyle } from '../Style'

export default function Register(
  nav: NativeStackScreenProps<RootStackParamList, 'Register'>
) {
  const theme = useTheme()

  return (
    <View style={BodyStyle(theme)}>
      <Navigation
        {...nav}
        title={Lang.get('register')}
        links={[{ icon: faSignIn, to: 'Login', replace: true }]}
      />
      <View>
        <Form<{ name: string; email: string; password: string }>
          onSubmit={async form => {
            if (form.email !== '' && form.password !== '' && form.name !== '') {
              const user = await createUser(
                form.email,
                form.password,
                form.name
              ).catch(() => null)
              if (user) {
                await AsyncStorage.setItem('api_key', user.token)
                setTimeout(() => {
                  nav.navigation.replace('Home')
                }, 1000)

                return [true, Lang.get('welcome')]
              }

              return [false, Lang.get('loginError')]
            }

            return [false, Lang.get('missing_name_or_email')]
          }}
          fields={[
            {
              name: 'name',
            },
            {
              name: 'email',
              type: 'email-address',
            },
            {
              name: 'password',
              hideChars: true,
            },
          ]}
          submitText={Lang.get('create')}
        />
      </View>
    </View>
  )
}
