import { createContext, ReactNode, useEffect, useState } from 'react'
import { Appearance } from 'react-native'

import AsyncStorage from '@react-native-async-storage/async-storage'

import TTheme from './Themes/TTheme'

export const ThemeContext = createContext<{
  theme: TTheme
  setTheme: (_t: TTheme) => void
}>({ theme: 'dark', setTheme: _t => {} })

export function ThemeProvider(props: { children: ReactNode }) {
  const [theme, setTheme] = useState<TTheme>('dark')

  Appearance.addChangeListener(data => {
    AsyncStorage.getItem('theme')
      .catch(() => 'auto')
      .then(t => {
        if (t === 'auto' && data.colorScheme) setTheme(data.colorScheme)
        if (t === 'dark') setTheme('dark')
        if (t === 'light') setTheme('light')
      })
  })
  useEffect(() => {
    AsyncStorage.getItem('theme')
      .catch(() => {
        const colorScheme = Appearance.getColorScheme()
        if (colorScheme) {
          return 'dark'
        }
      })
      .then(t => {
        if (t === 'dark') setTheme('dark')
        if (t === 'light') setTheme('light')
      })
    const colorScheme = Appearance.getColorScheme()
    if (colorScheme) {
      setTheme(colorScheme)
    }
  }, [])

  return (
    <>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        {props.children}
      </ThemeContext.Provider>
    </>
  )
}
