import { useEffect, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import {
  faCheck,
  faUser,
  faUserDoctor,
  faUserSecret,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons'

import { getAdminUsers, IAPIAdminUsers } from '../API/admin/AdminUsers'
import { LishaElementGroup } from '../Components/Element'
import Navigation, { NavigationProps } from '../Components/Navigation'
import SidebarNavigation from '../Components/SidebarNavigation'
import useTheme from '../hooks/useTheme'
import Lang from '../lang/Lang'
import { BodyStyle } from '../Style'

export default function Users(nav: NavigationProps<'Users'>) {
  const insets = useSafeAreaInsets()

  const [items, setItem] = useState<IAPIAdminUsers[]>([])

  useEffect(() => {
    getAdminUsers().then(setItem)
  }, [])
  const theme = useTheme()

  const roles: { [key: string]: IconDefinition } = {
    '0': faUser,
    '1': faUserDoctor,
    '2': faUserSecret,
  }

  return (
    <SidebarNavigation {...nav}>
      <View style={BodyStyle(theme)}>
        <Navigation
          back="Users"
          {...nav}
          title={Lang.get('administration')}
          subTitle={Lang.get('users')}
        />
        <ScrollView style={{ marginBottom: insets.bottom }}>
          <LishaElementGroup
            elements={items.map(e => ({
              text: e.name,
              iconLeft:
                e.role.toString() in roles ? roles[e.role.toString()] : faCheck,
              bottomLeft: e.email,
              bottomRight: `Last Login: ${new Date(
                e.last_login * 1000
              ).toLocaleDateString()} - Member since: ${new Date(
                e.register_date * 1000
              ).toLocaleDateString()}`,
              textRight: `${e.lists} Lists`,
            }))}
          />
        </ScrollView>
      </View>
    </SidebarNavigation>
  )
}
