import { useEffect, useState } from 'react'

import { NativeStackScreenProps } from '@react-navigation/native-stack'

import Lang from '../lang/Lang'
import { RootStackParamList } from '../RootStackParamList'
import { desktopBreakPoint, windowWidth } from '../Style'
import { Info } from './Alert'

export default function CheckPWA(
  nav: NativeStackScreenProps<RootStackParamList, 'Home'>
) {
  const [isStandalone, setIsStandalone] = useState(false)
  useEffect(() => {
    try {
      setIsStandalone(window.matchMedia('(display-mode: standalone)').matches)
    } catch (e) {
      setIsStandalone(true)
    }
  }, [])

  return (
    <>
      {isStandalone === false && windowWidth() <= desktopBreakPoint && (
        <Info
          onPress={() => nav.navigation.push('PWA')}
          text={Lang.get('pwa_alert')}
        />
      )}
    </>
  )
}
