import { useEffect, useState } from 'react'
import { Pressable, Text, View } from 'react-native'
// import Animated from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import useTheme from '../hooks/useTheme'
import ILang from '../lang/ILang'
import Lang from '../lang/Lang'
import { OptionsFlyoutStyle } from '../Style'
import { IElement, LishaElementGroup } from './Element'

interface IOptionsFlyout {
  title?: keyof ILang
  elements: (IElement | false)[]
  onGone: () => void
}

export default function OptionsFlyout(props: IOptionsFlyout) {
  const [opacity, setOpacity] = useState(0.0)
  const [bottom, setBottom] = useState(-props.elements.length * 60)
  const theme = useTheme()
  const optionsFlyoutStyle = OptionsFlyoutStyle(theme)

  function flyIn() {
    setOpacity(e => {
      if (e <= 0.95) setTimeout(flyIn, 10)

      return e + 0.05
    })
  }

  function flyOut() {
    setOpacity(e => {
      if (e >= 0.05) {
        setTimeout(flyOut, 10)
      } else {
        props.onGone()
      }

      return e - 0.05
    })
  }

  function flyUp() {
    setBottom(e => {
      if (e <= 0) setTimeout(flyUp, 10)
      let m = 10
      if (e + m > 0) m = -e

      return e + m
    })
  }

  useEffect(() => {
    flyIn()
    flyUp()
  }, [])
  const insets = useSafeAreaInsets()

  const elements: IElement[] = []
  props.elements.forEach(e => {
    if (e !== false) {
      elements.push(e)
    }
  })

  return (
    <View style={[optionsFlyoutStyle.darkenPanel, { opacity }]}>
      <Pressable style={{ flex: 1 }} onPress={() => flyOut()}>
        <View
          style={[
            optionsFlyoutStyle.box,
            { bottom, paddingBottom: insets.bottom },
          ]}
        >
          <Text style={optionsFlyoutStyle.haedline}>
            {Lang.get(props.title ?? 'options')}
          </Text>
          <LishaElementGroup elements={elements} />
        </View>
      </Pressable>
    </View>
  )
}
