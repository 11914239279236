import { useCallback, useEffect, useState } from 'react'
import { RefreshControl, ScrollView, View } from 'react-native'

import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import {
  faArrowUpFromBracket,
  faEllipsisVertical,
  faRefresh,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { NativeStackScreenProps } from '@react-navigation/native-stack'

import {
  deleteBoughtListItems,
  deleteListItem,
  deleteListItems,
  getListItems,
  updateListItem,
} from '../API/Items'
import { LishaElementGroup } from '../Components/Element'
import Navigation from '../Components/Navigation'
import OptionsFlyout from '../Components/OptionsFylout'
import SidebarNavigation from '../Components/SidebarNavigation'
import TopBarFlyout from '../Components/TopBarFlyout'
import useTheme from '../hooks/useTheme'
import IAPIItemsResponse from '../interfaces/IAPIItemsResponse'
import Lang from '../lang/Lang'
import { RootStackParamList } from '../RootStackParamList'
import {
  BodyStyle,
  ButtonRemoveStyle,
  ButtonUnboughtStyle,
  ListContentStyle,
  mainPadding,
} from '../Style'

export default function ListSeen(
  nav: NativeStackScreenProps<RootStackParamList, 'ListSeen'>
) {
  const { listId } = nav.route.params

  const [list, setList] = useState<IAPIItemsResponse>({
    id: -1,
    name: '',
    items: { bought: [], unbought: [] },
    user: 0,
    admin: '',
    isAdmin: true,
    access: {
      accepted: false,
      delete: false,
      read: false,
      update: false,
      write: false,
    },
  })

  const [refreshing, setRefreshing] = useState(false)

  async function loadData() {
    if (!listId) return
    await getListItems(listId).then(setList)
    setRefreshing(false)
  }

  const onRefresh = useCallback(() => {
    setRefreshing(true)
    loadData()
  }, [])

  useEffect(() => {
    if (!listId) return
    loadData()

    const interval = setInterval(() => {
      getListItems(listId).then(setList)
    }, 3 * 1000)

    return () => clearInterval(interval)
  }, [listId])

  const [isDeleteAllOpen, setDeleteAllOpen] = useState(false)
  const [isDeleteBoughtOpen, setDeleteBoughtOpen] = useState(false)

  const [optionsOpen, setOptionsOpen] = useState(false)

  useEffect(() => {
    setDeleteAllOpen(false)
    setDeleteBoughtOpen(false)
  }, [list])
  const theme = useTheme()

  return (
    <SidebarNavigation {...nav}>
      <View style={BodyStyle(theme)}>
        <Navigation
          {...nav}
          title={list.name}
          subTitle={Lang.get('seenItems')}
          back="Home"
          links={[
            {
              icon: faRefresh,
              onClick: () => {
                loadData()
              },
            },
            {
              icon: faEllipsisVertical,
              onClick: () => setOptionsOpen(e => !e),
            },
          ]}
        />
        {isDeleteAllOpen && (
          <TopBarFlyout
            items={[
              {
                title: Lang.get('sureDelete'),
                onClick: () => {
                  deleteListItems(list.id).then(setList)
                },
              },
            ]}
          />
        )}
        {isDeleteBoughtOpen && (
          <TopBarFlyout
            items={[
              {
                title: Lang.get('sureDelete'),
                onClick: () => {
                  deleteBoughtListItems(list.id).then(setList)
                },
              },
            ]}
          />
        )}
        <View
          style={{
            // marginBottom: InputBarStyle(insets, theme).box.height,
            marginTop: -mainPadding,
            overflow: 'hidden',
            flex: 1,
          }}
        >
          <ScrollView
            refreshControl={
              <RefreshControl
                title={Lang.get('reloading')}
                tintColor="#fff"
                titleColor="#fff"
                colors={['#fff']}
                refreshing={refreshing}
                onRefresh={onRefresh}
              />
            }
          >
            <View
              style={{
                ...ListContentStyle(theme),
              }}
            >
              <LishaElementGroup
                elements={list.items.bought.map(e => ({
                  text: e.name,
                  id: e.id,
                  amount: e.amount,
                  buttonRight: [
                    {
                      icon: faArrowUpFromBracket,
                      onPress: () => {
                        updateListItem(list.id, e.name, e.amount, false).then(
                          setList
                        )
                      },
                      style: ButtonUnboughtStyle(theme),
                    },
                  ],
                  swipeRight: [
                    {
                      icon: faTrashCan,
                      onPress: () => {
                        deleteListItem(list.id, e.id).then(setList)
                      },
                      style: ButtonRemoveStyle(theme),
                    },
                  ],
                }))}
              />
            </View>
          </ScrollView>
        </View>
        {optionsOpen && (
          <OptionsFlyout
            title="listoptions"
            elements={[
              {
                text: Lang.get('deleteElements'),
                iconLeft: faTrash,
                swipeRight: [
                  {
                    icon: faTrash,
                    onPress: () => {
                      setOptionsOpen(false)
                      deleteBoughtListItems(list.id).then(setList)
                    },
                    style: ButtonRemoveStyle(theme),
                  },
                ],
              },
            ]}
            onGone={() => {
              setOptionsOpen(false)
            }}
          />
        )}
      </View>
    </SidebarNavigation>
  )
}
