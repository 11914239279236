import { LishaElementGroup } from '../Components/Element'
import IAPIListDetails from '../interfaces/IAPIListDetails'
import Lang from '../lang/Lang'

export default function FListCreator(list: IAPIListDetails) {
  return (
    <LishaElementGroup
      elements={[
        {
          text: Lang.get('listCreatedBy', {
            name: list.admin.name,
            email: list.admin.email,
          }),
          bottomLeft: Lang.get('listCreatedByEmail', {
            name: list.admin.name,
            email: list.admin.email,
          }),
        },
      ]}
    />
  )
}
