import { useEffect } from 'react'
import { Pressable, Text, View } from 'react-native'

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import useTheme from '../hooks/useTheme'
import INavigation from '../interfaces/INavigation'
import { RootStackParamList } from '../RootStackParamList'
import { BarLinkStyle, mainPadding } from '../Style'
import { Theme } from '../Themes/Theme'

// eslint-disable-next-line import/order
import type { NativeStackScreenProps } from '@react-navigation/native-stack'

type Nav = keyof RootStackParamList

type NavigationProps<T extends Nav> = NativeStackScreenProps<
  RootStackParamList,
  T
>

export { NavigationProps }

export default function Navigation(props: INavigation) {
  const theme = useTheme()

  useEffect(() => {
    if (props.navigation)
      props.navigation.setOptions({
        title: props.title,
        headerShadowVisible: false,
        headerStyle: {
          backgroundColor: Theme.get('backgroundColorLightGrey', theme),
        },
        statusBarStyle: theme,
        statusBarTranslucent: false,
        statusBarColor: Theme.get('backgroundColorDarkGrey', theme),
        navigationBarColor: '#4ae53a',

        headerTintColor: Theme.get('DefaultColor', theme),
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        // headerTitleAlign: 'left',

        headerTitle: p => (
          <>
            <View
              style={{
                display: 'flex',
              }}
            >
              <Text
                style={{
                  color: Theme.get('DefaultColor', theme),
                  fontSize: 18,
                  fontWeight: 'bold',
                }}
              >
                {p.children}
              </Text>
              {props.subTitle && (
                <Text
                  style={{
                    color: Theme.get('DefaultColorDimmed', theme),
                    fontSize: 10,
                    fontWeight: 'bold',
                    opacity: 0.5,
                  }}
                >
                  {props.subTitle}
                </Text>
              )}
            </View>
          </>
        ),
        headerLeft: p => {
          if (
            !p.canGoBack &&
            (!props.route.path ||
              props.route.path === '/' ||
              props.route.path === '/?mode=standalone')
          ) {
            return
          }

          return (
            <Pressable
              onPress={() => {
                if (!p.canGoBack) {
                  props.navigation.popToTop()
                  props.navigation.replace('Home')
                  props.navigation.pop()
                } else {
                  props.navigation.goBack()
                }
              }}
            >
              <FontAwesomeIcon
                style={{
                  paddingLeft: 25,
                  paddingVertical: 15,
                  // @ts-ignore
                  outlineStyle: 'none',
                }}
                color={Theme.get('DefaultColor', theme)}
                icon={faChevronLeft}
              />
            </Pressable>
          )
        },
        headerRight: () => (
          <View
            style={{
              alignSelf: 'flex-end',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {props.links?.map((link, idx) => {
              if (link === false) return <></>

              return (
                <Pressable
                  key={`${link.to}${idx}`}
                  style={BarLinkStyle(theme)}
                  onPress={() => {
                    if (link.onClick) {
                      link.onClick()
                    } else if (link.to) {
                      if (link.replace) {
                        props.navigation.replace(link.to)
                      } else {
                        props.navigation.push(link.to)
                      }
                    }
                  }}
                >
                  <FontAwesomeIcon
                    // @ts-ignore
                    style={{ paddingTop: 0, outlineStyle: 'none' }}
                    color={Theme.get('DefaultColor', theme)}
                    size={18}
                    icon={link.icon}
                  />
                </Pressable>
              )
            })}
          </View>
        ),
      })
  }, [props.navigation, props.title, theme])

  return (
    <View
      style={{
        height: 1,
        left: -mainPadding,
        right: -mainPadding,
        zIndex: 1,
        top: 0,
        backgroundColor: Theme.get(
          theme === 'light'
            ? 'borderColorLightGrey'
            : 'backgroundColorDarkGrey',
          theme
        ),
        marginBottom: mainPadding,
        position: 'absolute',
      }}
    ></View>
  )
}
