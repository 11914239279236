import { useEffect, useState } from 'react'
import { ScrollView, Text, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { getChangelog } from '../API/Changelog'
import BottomNavigation from '../Components/BottomNavigation'
import Navigation, { NavigationProps } from '../Components/Navigation'
import SidebarNavigation from '../Components/SidebarNavigation'
import useTheme from '../hooks/useTheme'
import IAPIChangelog from '../interfaces/IAPIChangelog'
import Lang from '../lang/Lang'
import { BodyStyle } from '../Style'
import { Theme } from '../Themes/Theme'

const Item = ({ text, version, date }: IAPIChangelog) => {
  const theme = useTheme()

  return (
    <>
      <Text
        style={{
          color: Theme.get('DefaultColor', theme),
          marginBottom: 10,
          fontSize: 20,
          fontWeight: 'bold',
        }}
      >
        v{version} ({new Date(date).toLocaleDateString()})
      </Text>
      {text.map((t, idx) => (
        <Text
          key={idx}
          style={{
            opacity: 0.8,
            color: Theme.get('DefaultColor', theme),
            marginBottom: 10,
          }}
        >
          {t}
        </Text>
      ))}
    </>
  )
}

export default function Changelog(nav: NavigationProps<'Changelog'>) {
  const insets = useSafeAreaInsets()

  const [items, setItem] = useState<IAPIChangelog[]>([])

  useEffect(() => {
    getChangelog().then(setItem)
  }, [])
  const theme = useTheme()

  return (
    <SidebarNavigation {...nav}>
      <View style={BodyStyle(theme)}>
        <Navigation back="Changelog" {...nav} title={Lang.get('changelog')} />
        <ScrollView style={{ marginBottom: insets.bottom }}>
          {items.map(Item)}
        </ScrollView>
        <BottomNavigation {...nav} />
      </View>
    </SidebarNavigation>
  )
}
